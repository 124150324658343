import {
  FETCH_REMINDERS_SUCCESS,
  ADD_REMINDER,
  UPDATE_REMINDER,
  DELETE_REMINDER,
  SET_SELECTED_REMINDER,
  SET_REMINDER_ALERT,
  SET_REMINDER_ALERT_TIME,
} from 'actions/types'
import { reminders, reminderAdd, reminderUpdate, reminderDelete, reminderSetAlert, reminderSetAlertTime } from "../api";
import {
  dataFailure,
  dataSuccess,
} from "./general";

const fetchRemindersSuccess = (reminders) => {
  return {
    type: FETCH_REMINDERS_SUCCESS,
    payload: reminders
  }
}

export const fetchReminders = (values) => {
  return async (dispatch) => {
    try {
      const returnJSONData = await dispatch(reminders(values))
      await dispatch(fetchRemindersSuccess(returnJSONData))
    } catch(err) {
      await dispatch(dataFailure(err.message))
    }
  }
}

const addReminderData = (values) => {
  return {
    type: ADD_REMINDER,
    payload: values
  }
}

export const addReminder = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(reminderAdd(values));
      await dispatch(addReminderData(JSONData));
      await dispatch(dataSuccess())
    } catch(err) {
      await dispatch(dataFailure(err.message))
    }
  }
}

const updateReminderData = (values) => {
  return {
    type: UPDATE_REMINDER,
    payload: values
  }
}

export const updateReminder = (values) => {
  return async (dispatch) => {
    
    try {
      const JSONData = await dispatch(reminderUpdate(values));
      await dispatch(updateReminderData(JSONData));
      await dispatch(dataSuccess())
    } catch(err) {
      await dispatch(dataFailure(err.message))
    }
  }
}

const deleteReminderData = (values) => {
  return {
    type: DELETE_REMINDER,
    payload: values
  }
}

export const deleteReminder = (values) => {
  return async (dispatch) => {
    
    try {
      await dispatch(deleteReminderData(values));
      await dispatch(reminderDelete(values))
      await dispatch(dataSuccess())
    } catch(err) {
      await dispatch(dataFailure(err.message))
    }
  }
}

export const setSelectedReminder = (values) => {
  return {
    type: SET_SELECTED_REMINDER,
    payload: values
  }
}

// export const setDisplayReminder = (values) => {
//   return {
//     type: SET_DISPLAY_REMINDER,
//     payload: values
//   }
// }

const saveReminderAlert = (values) => {
  return {
    type: SET_REMINDER_ALERT,
    payload: values,
  };
};

export const setReminderAlert = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(saveReminderAlert(values));
      await dispatch(reminderSetAlert(values));
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

const saveReminderAlertTime = (values) => {
  return {
    type: SET_REMINDER_ALERT_TIME,
    payload: values,
  };
};

export const setReminderAlertTime = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(saveReminderAlertTime(values));
      await dispatch(reminderSetAlertTime(values));
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};