import {
  FETCH_DASHBOARD_SUCCESS,
  FETCH_DASHBOARD_FAILURE
} from 'actions/types';

const initialState = {
  Dashboard: {},
  error: ''
}

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DASHBOARD_SUCCESS:
      return {
        ...state,
        Dashboard: action.payload,
        loading: false,
        error: "",
      };  
    case FETCH_DASHBOARD_FAILURE:
      return {
        ...state,
        error: action.payload,
      };     
    default: return state;
  }
};

export default dashboardReducer   