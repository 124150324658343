import {
  FETCH_COMPANY_LOCATIONS_SUCCESS,
  ADD_COMPANY_LOCATION,
  UPDATE_COMPANY_LOCATION,
  UPLOAD_LOGO,
  COMPANY_FAILURE,
} from "actions/types";
import produce from "immer";

const initialState = {
  Locations: [],
  error: "",
};

const companyReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    var key;
    switch (action.type) {
      case FETCH_COMPANY_LOCATIONS_SUCCESS:
        draft.Locations = action.payload;
        draft.error = "";
        return;
      case COMPANY_FAILURE:
        draft.error = action.payload;
        break;
      case ADD_COMPANY_LOCATION:
        draft.Locations = [...draft.Locations, action.payload];
        if (action.payload.isHeadquarter) {
          draft.Locations.filter((L) => parseInt(L.id) !== parseInt(action.payload.id)).map(
            (L) => (L.isHeadquarter = false)
          );
        }
        draft.error = "";
        break;
      case UPDATE_COMPANY_LOCATION:
        key = draft.Locations.findIndex((C) => parseInt(C.id) === parseInt(action.payload.id));
        draft.Locations[key] = action.payload;
        if (action.payload.isHeadquarter) {
          draft.Locations.filter((L) => parseInt(L.id) !== parseInt(action.payload.id)).map(
            (L) => (L.isHeadquarter = false)
          );
        }
        return;
      case UPLOAD_LOGO:
        key = draft.Locations.findIndex((C) => C.id === action.payload.id);
        draft.Locations[key] = {
          ...draft.Locations[key],
          companyLogo: action.payload,
        };
        return;
      default:
        return draft;
    }
  });

export default companyReducer;
