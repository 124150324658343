import {
  FETCH_PERSON_SUCCESS,
  FETCH_PERSONS_MIN_SUCCESS,
  UPDATE_PERSON,
  ADD_PERSON,
  SET_PERSON_ID,
} from "./types";

import { person, personsMinimal, personsUpdate, personsAdd, personSetRoll } from "../api";
import { dataFailure, dataSuccess } from "./general";

const fetchPersonSuccess = (values) => {
  return {
    type: FETCH_PERSON_SUCCESS,
    payload: values,
  };
};

const fetchPersonsMinSuccess = (Persons) => {
  return {
    type: FETCH_PERSONS_MIN_SUCCESS,
    payload: Persons,
  };
};

export const fetchPerson = (values) => {
  return async (dispatch) => {
    try {
      const personsJSONData = await dispatch(person(values));
      await dispatch(fetchPersonSuccess(personsJSONData));
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

export const fetchPersonsMinimal = (values) => {
  return async (dispatch) => {
    try {
      const personsJSONData = await dispatch(personsMinimal(values));
      await dispatch(fetchPersonsMinSuccess(personsJSONData));
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

const updatePersonData = (values) => {
  return {
    type: UPDATE_PERSON,
    payload: values,
  };
};

export const updatePerson = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(personsUpdate(values));
      await dispatch(updatePersonData(values));
      await dispatch(dataSuccess());
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

const addPersonData = (values) => {
  return {
    type: ADD_PERSON,
    payload: values,
  };
};

export const addPerson = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(personsAdd(values));
      await dispatch(addPersonData(JSONData));
      await dispatch(dataSuccess());
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

export const setPersonId = (id) => {
  return {
    type: SET_PERSON_ID,
    payload: id,
  };
};

export const setRoll = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(personSetRoll(values));
      await dispatch(dataSuccess());
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };  
}
