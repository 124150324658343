import {
  FETCH_MONTH_DATA_SUCCESS,
  FETCH_MONTH_STATIC_DATA_SUCCESS,
  FETCH_MONTH_USER_DATA_SUCCESS,
  FETCH_MONTH_SUM_DATA_SUCCESS,
  SET_DISPLAY_DEPARTMENT,
} from "./types";

import { monthStaticData, monthUserData, monthSumData } from "../api";

import {
  dataFailure,
} from "./general";

const monthDataSuccess = data => {
  return {
    payload: {
      ...data,
    },
    type: FETCH_MONTH_DATA_SUCCESS,
  }
}

const monthStaticDataSuccess = data => {
  return {
    payload: data,
    type: FETCH_MONTH_STATIC_DATA_SUCCESS,
  }
}

const monthUserDataSuccess = data => {
  return {
    payload: data,
    type: FETCH_MONTH_USER_DATA_SUCCESS,
  }
}

const monthSumDataSuccess = data => {
  return {
    payload: data,
    type: FETCH_MONTH_SUM_DATA_SUCCESS,
  }
}

export const fetchMonth = (values) => {
  return async (dispatch) => {
    try {
      const monthStatic = await dispatch(monthStaticData(values));
      const sums = await dispatch(monthSumData(values));
      const offdays = await dispatch(monthUserData(values));
      await dispatch(monthDataSuccess({ monthStatic, sums, offdays }));
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

export const fetchMonthStatic = (values) => {
  return async (dispatch) => {
    try {
      const monthStatic = await dispatch(monthStaticData(values));
      await dispatch(monthStaticDataSuccess(monthStatic));
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

export const fetchMonthUser = (values) => {
  return async (dispatch) => {
    try {
      const monthUser = await dispatch(monthUserData(values));
      await dispatch(monthUserDataSuccess(monthUser));
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

export const fetchMonthSums = (values) => {
  return async (dispatch) => {
    try {
      const monthSums = await dispatch(monthSumData(values));
      await dispatch(monthSumDataSuccess(monthSums));
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

const setDisplayDepartmentData = (id) => {
  return {
    type: SET_DISPLAY_DEPARTMENT,
    payload: id,
  };
};

export const setDisplayDepartment = (id) => {
  return async (dispatch) => {
    try {
      await dispatch(setDisplayDepartmentData(id));
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};
