import React from "react";
import { Box } from "@mui/material";
import OpenWithIcon from "@mui/icons-material/OpenWith";
import CloseIcon from "@mui/icons-material/Close";

const DialogTitle = (props) => {
  let lastColor = "";

  function handleClose() {
    props.closeForm();
  }

  function redBackground(e) {
    if (lastColor === "") {
      lastColor = e.target.style.color;
    }
    e.target.style.background = "red";
    e.target.style.color = "white";
  }

  function normalBackground(e) {
    e.target.style.background = "transparent";
    e.target.style.color = lastColor;
  }

  return (
    <Box
      sx={{
        padding: 0,
        cursor: "move",
        borderBottom: "2px solid grey",
        display: "grid",
        gridTemplateColumns: "24px 1fr 32px",
        gridTemplateRows: "2rem",
        minWidth: props.width,
        backgroundColor: props.backgroundColor,
      }}
      id={props.id || "draggable-dialog-title"}
    >
      {!props.noMoveIcon && (
        <Box
          sx={{
            width: "24px",
            height: "24px",
            margin: "2px",
            gridColumn: "1",
            gridRow: "1",
            color: props.color,
            backgroundColor: props.backgroundColor,
          }}
        >
          <OpenWithIcon />
        </Box>
      )}
      <Box
        sx={{
          gridRow: "1",
          gridColumn: "2",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          fontSize: "1.2rem",
          fontWeight: "400",
          color: props.color,
        }}
      >
        {props.dialogTitle}
      </Box>
      {!props.noCloseIcon && (
        <Box
          sx={{
            width: "1.5rem",
            height: "1.5rem",
            margin: "4px",
            gridColumn: "3",
            gridRow: "1",
            cursor: "default",
            color: props.color,
          }}
        >
          <CloseIcon
            onMouseEnter={redBackground}
            onMouseLeave={normalBackground}
            onClick={handleClose}
          />
        </Box>
      )}
    </Box>
  );
};

export default DialogTitle;
