import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

const PreviousButton = (props) => {
  return (
    <IconButton onClick={props.onClick} sx={props.sx}>
      <ChevronLeftIcon />
    </IconButton>
  );
};

export default PreviousButton;
