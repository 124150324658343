import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { getGlobalSetting } from "Components/Utilities";
import { store } from "redux/store";
import { savePassword, logoutUser } from "redux/index";
import { Box } from "@mui/system";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "Calendars/Components/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import { Grid } from "@mui/material";
import SomuraTextField from "Components/SomuraTextField";
import SomuraProgress from "Components/SomuraProgress";
import SomuraTooltip from "../Components/SomuraTooltip";

import CancelButton from "Components/Buttons/CancelButton";
import SaveButton from "Components/Buttons/SaveButton";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

var sha256 = require("js-sha256");

const mapStateToProps = (state) => {
  return {
    loggedUser: state.loggeduser.LoggedUser,
    // progressValue: state.general.progressValue,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    savePassword: (pw) => dispatch(savePassword(pw)),
    logoutUser: () => dispatch(logoutUser()),
  };
};

const NewPassword = ({loggedUser, savePassword, logoutUser, closeNewPassword}) => {
  const { t } = useTranslation();
  const [state, setState] = useState({
    username: loggedUser.emailCompany === "" ? loggedUser.username : loggedUser.emailCompany,
    password: "",
    password2: "",
  });
  const [progressValue, setProgressValue] = useState(0);

  const component = "passwords";
  const minLength = getGlobalSetting(component, "minLength");
  const useCapitalChars =
    parseInt(getGlobalSetting(component, "useCapitalChars")) === 1;
  const minCapitalChars = parseInt(
    getGlobalSetting(component, "minCapitalChars")
  );
  const useNormalChars =
    parseInt(getGlobalSetting(component, "useNormalChars")) === 1;
  const minNormalChars = parseInt(
    getGlobalSetting(component, "minNormalChars")
  );
  const useNumbers = parseInt(getGlobalSetting(component, "useNumbers")) === 1;
  const minNumbers = parseInt(getGlobalSetting(component, "minNumbers"));
  const useSpecialChars =
    parseInt(getGlobalSetting(component, "useSpecialChars")) === 1;
  const minSpecialChars = parseInt(
    getGlobalSetting(component, "minSpecialChars")
  );
  const uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÜ";
  const lowercase = "abcdefghijklmnopqrstuvwxyzäöüß";
  const number = "0123456789";
  const special = "!$%&/()=*+-#.,;:@?{}[]";
  var progValue;
  var numCrit;

  const title = () => {
    numCrit = 1;
    var sublines = [];
    sublines.push(
      <Box
        sx={{
          fontSize: "0.8rem",
          margin: "1px 0px",
          padding: "0px 4px",
          borderRadius: "0.5rem",
        }}
        key="0"
      >
        {t("Minimum length")}: {minLength} {t("characters")}
      </Box>
    );
    if (useCapitalChars) {
      numCrit++;
      sublines.push(
        <Box
          sx={{
            fontSize: "0.8rem",
            margin: "1px 0px",
            padding: "0px 4px",
            borderRadius: "0.5rem",
          }}
          key="1"
        >
          {t("Minimum")} {minCapitalChars} {t("capital letters")}
        </Box>
      );
    }
    if (useNormalChars) {
      numCrit++;
      sublines.push(
        <Box
          sx={{
            fontSize: "0.8rem",
            margin: "1px 0px",
            padding: "0px 4px",
            borderRadius: "0.5rem",
          }}
          key="2"
        >
          {t("Minimum")} {minNormalChars} {t("lowercase letters")}
        </Box>
      );
    }
    if (useNumbers) {
      numCrit++;
      sublines.push(
        <Box
          sx={{
            fontSize: "0.8rem",
            margin: "1px 0px",
            padding: "0px 4px",
            borderRadius: "0.5rem",
          }}
          key="3"
        >
          {t("Minimum")} {minNumbers} {t("digits")}
        </Box>
      );
    }
    if (useSpecialChars) {
      numCrit++;
      sublines.push(
        <Box
          sx={{
            fontSize: "0.8rem",
            margin: "1px 0px",
            padding: "0px 4px",
            borderRadius: "0.5rem",
          }}
          key="4"
        >
          {t("Minimum")} {minSpecialChars} {t("special characters")}
        </Box>
      );
    }
    return (
      <>
        <Box
          sx={{
            fontSize: "0.8rem",
            margin: "1px 0px",
            padding: "0px 4px",
            borderRadius: "0.5rem",
          }}
        >
          {t("Password requirements")}:
        </Box>
        <div>{sublines}</div>
      </>
    );
  };

  const handleSubmit = () => {
    savePassword({ ...state, password: sha256(state.password) });
    store.dispatch({ type: "SET_LOGIN_STATE", payload: "2" });
    store.dispatch({ type: "DATA_SUCCESS" });
    closeNewPassword();
    // Log user out so he has to relog with new password
    localStorage.removeItem("jwt");
    logoutUser();
    window.location.reload(false);
  };

  const handleCancel = () => {
    store.dispatch({ type: "SET_LOGIN_STATE", payload: "0" });
    closeNewPassword();
  };

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
    if (event.currentTarget.name === "password") {
      checkPassword(event.currentTarget.value);
    }
  };

  useEffect(() => {
    setState({
      username:
        loggedUser.emailCompany === ""
          ? loggedUser.username
          : loggedUser.emailCompany,
      password: "",
      password2: "",
    });
  }, [loggedUser]);

  const checkPassword = (pw) => {
    let numUpper = 0;
    let numLower = 0;
    let numNumber = 0;
    let numSpecial = 0;
    let check = "";
    var arrFull = [false, false, false, false, false];

    if (pw.length >= minLength) {
      arrFull[0] = true;
    }
    for (var i = 0; i < pw.length; i++) {
      check = pw.substring(i, i + 1);
      if (useCapitalChars && uppercase.indexOf(check) > -1) {
        numUpper++;
        if (numUpper >= minCapitalChars) {
          arrFull[1] = true;
        }
      }
      if (useNormalChars && lowercase.indexOf(check) > -1) {
        numLower++;
        if (numLower >= minNormalChars) {
          arrFull[2] = true;
        }
      }
      if (useNumbers && number.indexOf(check) > -1) {
        numNumber++;
        if (numNumber >= minNumbers) {
          arrFull[3] = true;
        }
      }
      if (useSpecialChars && special.indexOf(check) > -1) {
        numSpecial++;
        if (numSpecial >= minSpecialChars) {
          arrFull[4] = true;
        }
      }
    }

    progValue = (arrFull.filter((AF) => AF === true).length * 100) / numCrit;
    setProgressValue(progValue);
    store.dispatch({ type: "SET_PROGRESS_VALUE", payload: progValue });
  };

  useEffect(() => {
    store.dispatch({ type: "SET_PROGRESS_VALUE", payload: 0 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog
      open={true}
      onKeyUp={(e) => {
        if (e.key === "Enter" && !e.shiftKey) {
          handleSubmit();
        }
        if (e.key === "Escape") {
          handleCancel();
        }
      }}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleCancel();
        }
      }}
      disableEscapeKeyDown={true}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle
        sx={{
          backgroundColor: "background.scrollBarThumb",
        }}
        dialogTitle={t("Change password")}
        closeForm={handleCancel}
      />
      <DialogContent
        sx={{
          backgroundColor: "background.scrollBarThumb",
        }}
      >
        <div>
          <Grid container spacing={2}>
            <Box
              sx={{
                fontSize: "1.5rem",
                textAlign: "center",
                paddingBottom: "0",
                width: "100%",
              }}
            >
              {t("New password for")}
            </Box>
            <Box
              sx={{
                fontSize: "1rem",
                textAlign: "center",
                paddingTop: "0",
                width: "100%",
              }}
            >
              {state.username}
            </Box>
            <Grid item xs={12}>
              <SomuraTextField
                label={t("Password")}
                type="password"
                name="password"
                value={state.password}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} style={{ marginTop: "6px" }}>
            <Grid item xs={11}>
              <SomuraProgress progressValue={progressValue} />
            </Grid>
            <Grid
              item
              xs={1}
              sx={{
                paddingTop: "0 !important",
                color: "somura",
              }}
            >
              <SomuraTooltip title={title()} placement="right" arrow={true}>
                <div>
                  <InfoOutlinedIcon />
                </div>
              </SomuraTooltip>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <SomuraTextField
                label={t("Repeat password")}
                type="password"
                name="password2"
                value={state.password2}
                onChange={handleChange}
                disabled={progressValue < 100}
              />
            </Grid>
          </Grid>
        </div>
      </DialogContent>
      <DialogActions
        sx={{
          padding: "4px 10px !important",
          backgroundColor: "dialog.bottom",
        }}
      >
        <CancelButton
          onClick={() => {
            handleCancel();
          }}
        />
        <SaveButton
          disabled={progressValue < 100 || state.password !== state.password2}
          onClick={() => {
            handleSubmit();
          }}
        />
      </DialogActions>
    </Dialog>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(NewPassword);
