import React from "react";
import Badge from "@mui/material/Badge";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";

function RemindersBadge({ count, reminders, setReminderAlert }) {
  return count > 0 ? (
    <>
      <Badge
        sx={{ "& .MuiBadge-badge": { fontSize: "0.8rem" } }}
        color="error"
        showZero={false}
        badgeContent={count}
      >
        <NotificationsActiveOutlinedIcon />
      </Badge>
    </>
  ) : (
    <div></div>
  );
}

export default RemindersBadge;
