import {
  FETCH_YEAR_SUCCESS,
  FETCH_YEAR_STATIC_SUCCESS,
  FETCH_YEAR_COUNT_SUCCESS,
  FETCH_YEAR_USER_SUCCESS,
  FETCH_YEAR_TYPES_SUCCESS,
  SET_DISPLAY_USER,
  SET_DISPLAY_APPROVEMENT,
  SET_YEAR_WORKDAYS,
} from "./types";

import {
  yearData,
  yearStaticData,
  yearCountData,
  yearUserData,
  yearUserAbsenceTypes,
} from "../api";

import {
  dataFailure,
} from "./general";

const fetchYearSuccess = (yearData) => {
  return {
    type: FETCH_YEAR_SUCCESS,
    payload: yearData,
  };
};


export const fetchYear = (values) => {
  return async (dispatch) => {
    try {
      const yearJSONData = await dispatch(yearData(values));
      await dispatch(fetchYearSuccess(yearJSONData));
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

const fetchYearStaticSuccess = (yearData) => {
  return {
    type: FETCH_YEAR_STATIC_SUCCESS,
    payload: yearData,
  };
};

export const fetchYearStatic = (values) => {
  return async (dispatch) => {
    try {
      const yearJSONData = await dispatch(yearStaticData(values));
      await dispatch(fetchYearStaticSuccess(yearJSONData));
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

const fetchYearCountSuccess = (yearData) => {
  return {
    type: FETCH_YEAR_COUNT_SUCCESS,
    payload: yearData,
  };
};

export const fetchYearCount = (values) => {
  return async (dispatch) => {
    try {
      const yearJSONData = await dispatch(yearCountData(values));
      await dispatch(fetchYearCountSuccess(yearJSONData));
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

const fetchYearUserSuccess = (yearData) => {
  return {
    type: FETCH_YEAR_USER_SUCCESS,
    payload: yearData,
  };
};
export const fetchYearUser = (values) => {
  return async (dispatch) => {
    try {
      const yearJSONData = await dispatch(yearUserData(values));
      await dispatch(fetchYearUserSuccess(yearJSONData));
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

const fetchYearTypesSuccess = (yearData) => {
  return {
    type: FETCH_YEAR_TYPES_SUCCESS,
    payload: yearData,
  };
};
export const fetchYearUserAbsenceTypes = (values) => {
  return async (dispatch) => {
    try {
      const yearJSONData = await dispatch(yearUserAbsenceTypes(values));
      await dispatch(fetchYearTypesSuccess(yearJSONData));
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

const setDisplayUserData = (id) => {
  return {
    type: SET_DISPLAY_USER,
    payload: id,
  };
};

export const setDisplayUser = (id) => {
  return async (dispatch) => {
    try {
      await dispatch(setDisplayUserData(id));
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

const setDisplayApprovementData = (id) => {
  return {
    type: SET_DISPLAY_APPROVEMENT,
    payload: id,
  };
};

export const setDisplayApprovement = (id) => {
  return async (dispatch) => {
    try {
      await dispatch(setDisplayApprovementData(id));
    } catch (err) {
      console.error("failed to set display approvement", err); // eslint-disable-line no-console
      await dispatch(dataFailure(err.message));
    }
  };
};

export const setYearWorkdays = (value) => {
  return {
    type: SET_YEAR_WORKDAYS,
    payload: value
  };
}