import React from "react";
import Tooltip from "@mui/material/Tooltip";

const SomuraTooltip = ({ calendar, ...props }) => {
  return (
    <Tooltip
      {...props}
      PopperProps={{
        sx: {
          "& .MuiTooltip-tooltip": {
            padding: props.padding,
            margin: "0",
            backgroundColor: "#f5f5f9",
            color: "rgba(0, 0, 0, 0.87)",
            fontSize: "0.9rem",
            border: props.border,
            borderRadius: "5px",
            boxShadow: "5px 5px 3px 1px rgba(0, 0, 0, 0.4)",
          },
          "& .MuiTooltip-arrow": {
            "&::before": {
              backgroundColor: "#f5f5f9",
              border: props.border,
            },
          },
        },
      }}
      enterDelay={200}
      enterNextDelay={200}
      arrow
      border={calendar ? "2px solid #BDBDBD" : "1px solid #f5f5f9"}
      padding={calendar ? "0" : ""}
      disableInteractive
    >
      {props.children}
    </Tooltip>
  );
};

export default SomuraTooltip;
