import {
  FETCH_TIMES_SUCCESS,
  FETCH_LAST_TIMES_SUCCESS,
  FETCH_USERTIMES_SUCCESS,
  FETCH_USERTIMES_CUM_SUCCESS,
  FETCH_TIMES_FAULTY_SUCCESS,
  FETCH_USER_MONTH_TIMES_SUCCESS,
  FETCH_ALIVE_TIME_SUCCESS,
  FETCH_USER_TIME_STATE,
  SET_WEB_TIME,
  SET_TIME,
  END_TIME,
  SET_SELECTED_DATE,
  SET_SELECTED_TIME,
  SET_SELECTED_TIME_FORM_ID,
  UPDATE_TIME,
  DELETE_TIME,
  ADD_TIME,
  TERMINATE_DAY_SUCCESS,
  TERMINATE_DAY_USER_SUCCESS,
  TERMINATE_MONTH_SUCCESS,
  FETCH_TIME_SUMS,
  FETCH_TIME_STATE,
  FETCH_MONTH_TERMINATED,
  FETCH_NOT_TERMINATED,
} from "actions/types";

import {
  getTimes,
  getLastTimes,
  getTimesDay,
  getTimesCum,
  getTimesFaulty,
  getTimesMonth,
  setTimeFromWeb,
  setStartTime,
  setEndTime,
  timeUpdate,
  timeDelete,
  timeAdd,
  notTerminated,
  dayTermination,
  dayTerminationUser,
  monthTermination,
  saveAliveTime,
  getAliveTime,
  getPersonState,
  getMonthTerminated,
  resetMonthTermination,
  readTimeSums,
  getCurrentTimeState
} from "../api";
import {
  dataFailure,
  actionSuccess
} from "./general";

const fetchTimesSuccess = (values) => {
  return {
    type: FETCH_TIMES_SUCCESS,
    payload: values
  }
}

export const fetchTimes = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(getTimes(values))
      await dispatch(fetchTimesSuccess(JSONData))
    } catch(err) {
      await dispatch(dataFailure('times: fetch ' + err.message))
    }
  }
}

const fetchLastTimesSuccess = (values) => {
  return {
    type: FETCH_LAST_TIMES_SUCCESS,
    payload: values
  }
}

export const fetchLastTimes = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(getLastTimes(values))
      await dispatch(fetchLastTimesSuccess(JSONData))
    } catch(err) {
      await dispatch(dataFailure('times: fetch last ' + err.message))
    }
  }
}

const fetchUserTimesSuccess = (values) => {
  return {
    type: FETCH_USERTIMES_SUCCESS,
    payload: values
  }
}

export const fetchUserTimes = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(getTimesDay(values))
      await dispatch(fetchUserTimesSuccess(JSONData))
    } catch(err) {
      await dispatch(dataFailure('times: fetchUser ' + err.message))
    }
  }
}

const fetchUserTimesCumSuccess = (values) => {
  return {
    type: FETCH_USERTIMES_CUM_SUCCESS,
    payload: values
  }
}

export const fetchUserTimesCum = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(getTimesCum(values))
      await dispatch(fetchUserTimesCumSuccess(JSONData))
    } catch(err) {
      await dispatch(dataFailure('times: fetchUserCum ' + err.message))
    }
  }
}

const fetchTimesFaultySuccess = (values) => {
  return {
    type: FETCH_TIMES_FAULTY_SUCCESS,
    payload: values
  }
}

export const fetchTimesFaulty = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(getTimesFaulty(values))
      await dispatch(fetchTimesFaultySuccess(JSONData))
    } catch(err) {
      await dispatch(dataFailure('times: fetchTimesFaulty ' + err.message))
    }
  }
}

const fetchTimesMonthSuccess = (values) => {
  return {
    type: FETCH_USER_MONTH_TIMES_SUCCESS,
    payload: values
  }
}

export const fetchTimesMonth = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(getTimesMonth(values));
      await dispatch(fetchTimesMonthSuccess(JSONData))
    } catch(err) {
      await dispatch(dataFailure('times: fetchTimesMonth ' + err.message))
    }
  }
}

export const saveAlive = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(saveAliveTime(values));
    } catch (err) {
      await dispatch(dataFailure("times: aliveTime " + err.message));
    }
  };
}

const fetchAliveTimeSuccess = (values) => {
  return {
    type: FETCH_ALIVE_TIME_SUCCESS,
    payload: values
  };
}

export const fetchAliveTime = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(getAliveTime(values));
      await dispatch(fetchAliveTimeSuccess(JSONData));
    } catch (err) {
      await dispatch(dataFailure("times: fetchAliveTime " + err.message));
    }
  };
};

const fetchPersonStateData = (values) => {
  return {
    type: FETCH_USER_TIME_STATE,
    payload: values
  };
}

export const fetchPersonState = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(getPersonState(values));
      await dispatch(fetchPersonStateData(JSONData));
    } catch (err) {
      await dispatch(dataFailure("times: fetchPersonState " + err.message));
    }
  };
};

const setWebTimeData = (values) => {
  return {
    type: SET_WEB_TIME,
    payload: values
  }
}

export const setWebTime = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(setTimeFromWeb(values));
      await dispatch(setWebTimeData( JSONData ));
    } catch(err) {
      await dispatch(dataFailure('times: set web' + err.message))
    }
  }
}

const setTimeData = (values) => {
  return {
    type: SET_TIME,
    payload: values
  }
}

export const setTime = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(setStartTime(values));
      await dispatch(setTimeData( JSONData ));
    } catch(err) {
      await dispatch(dataFailure('times: set ' + err.message))
    }
  }
}

const endTimeData = (values) => {
  return {
    type: END_TIME,
    payload: values,
  };
};

export const endTime = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(setEndTime(values));
      await dispatch(endTimeData(JSONData));
    } catch(err) {
      await dispatch(dataFailure('times: end ' + err.message))
    }
  }
}

const updateTimeData = (values) => {
  return {
    type: UPDATE_TIME,
    payload: values
  }
}

export const updateTime = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(timeUpdate(values));
      await dispatch(updateTimeData(JSONData));
    } catch (err) {
      await dispatch(dataFailure('times: update ' + err.message));
    }
  };
};

const deleteTimeData = (values) => {
  return {
    type: DELETE_TIME,
    payload: values
  }
}

export const deleteTime = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(timeDelete(values));
      await dispatch(deleteTimeData(JSONData));
    } catch (err) {
      await dispatch(dataFailure('times: delete ' + err.message));
    }
  };
};

const addTimeData = (values) => {
  return {
    type: ADD_TIME,
    payload: values
  }
}

export const addTime = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(timeAdd(values));
      await dispatch(addTimeData(JSONData));
    } catch (err) {
      await dispatch(dataFailure('times: add ' + err.message));
    }
  };
};

export const setSelectedDate = (values) => {
  return {
    type: SET_SELECTED_DATE,
    payload: values,
  };
};

export const setSelectedTime = (values) => {
  return {
    type: SET_SELECTED_TIME,
    payload: values,
  };
};

export const setSelectedTimeFormId = (values) => {
  return {
    type: SET_SELECTED_TIME_FORM_ID,
    payload: values,
  };
};

const terminateDaySuccess = (values) => {
  return {
    type: TERMINATE_DAY_SUCCESS,
    payload: values,
  };
};

export const terminateDay = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(dayTermination(values));
      await dispatch(terminateDaySuccess(JSONData));
      dispatch(actionSuccess());
    } catch (err) {
      await dispatch(dataFailure("times: dayTermination " + err.message));
    }
  };
};

const terminateDayUserSuccess = (values) => {
  return {
    type: TERMINATE_DAY_USER_SUCCESS,
    payload: values,
  };
};

export const terminateDayUser = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(dayTerminationUser(values));
      await dispatch(terminateDayUserSuccess(JSONData));
      await dispatch(fetchUserTimesCum(values))
      dispatch(actionSuccess())
    } catch (err) {
      await dispatch(dataFailure("times: dayTermination " + err.message));
    }
  };
};

const terminateMonthSuccess = (values) => {
  return {
    type: TERMINATE_MONTH_SUCCESS,
    payload: values,
  };
};

export const terminateMonth = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(monthTermination(values));
      await dispatch(terminateMonthSuccess(JSONData));
      dispatch(actionSuccess());
    } catch (err) {
      await dispatch(dataFailure("times: monthTermination " + err.message));
    }
  };
};

const monthTerminatedSuccess = (values) => {
  return {
    type: FETCH_MONTH_TERMINATED,
    payload: values,
  };
};

export const monthTerminated = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(getMonthTerminated(values));
      await dispatch(monthTerminatedSuccess(JSONData));
    } catch (err) {
      await dispatch(dataFailure("times: monthTerminationState " + err.message));
    }
  };
};

const fetchNotTerminatedSuccess = (values) => {
  return {
    type: FETCH_NOT_TERMINATED,
    payload: values,
  };
};

export const fetchNotTerminated = () => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(notTerminated());
      await dispatch(fetchNotTerminatedSuccess(JSONData));
    } catch (err) {
      await dispatch(dataFailure("times: monthNotTerminationState " + err.message));
    }
  };
};

export const resetTermination = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(resetMonthTermination(values));
      dispatch(actionSuccess());
    } catch (err) {
      await dispatch(
        dataFailure("times: resetMonthTermination " + err.message)
      );
    }
  };  
}

const fetchTimeSumsData = (values) => {
  return {
    type: FETCH_TIME_SUMS,
    payload: values,
  };
};


export const fetchTimeSums = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(readTimeSums(values));
      await dispatch(fetchTimeSumsData(JSONData));
    } catch (err) {
      await dispatch(dataFailure("times: readMonthSums " + err.message));
    }
  };
};

const fetchTimeStateData = (values) => {
  return {
    type: FETCH_TIME_STATE,
    payload: values
  }
}

export const fetchTimeState = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(getCurrentTimeState(values));
      await dispatch(fetchTimeStateData(JSONData));
    } catch (err) {
      await dispatch(dataFailure("times: fetchTimeState " + err.message));
    }
  };
};