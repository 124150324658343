import React from "react";
import Slider from "@mui/material/Slider";
import { Box } from "@mui/material";

const SomuraSlider = ({
  labelLeft,
  labelRight,
  name,
  disabled,
  value,
  ...props
}) => {
  return (
    <Box
      sx={{
        marginRight: "20px",
        paddingRight: "10px",
        marginTop: "-10px",
      }}
    >
      <Box
        sx={{
          fontSize: "0.8rem",
        }}
      >
        {props.label}
      </Box>
      <Box
        sx={{
          marginTop: "-6px",
        }}
      >
        <Slider
          name={name}
          sx={{
            padding: "0 10px",
            color: props.color,
            backgroundColor: "disabled",
          }}
          value={value}
          onChange={props.onChange}
          valueLabelDisplay="auto"
          valueLabelFormat={props.valueLabelFormat}
          step={props.step}
          marks={props.marks}
          min={props.min}
          max={props.max}
          disabled={disabled}
        />
      </Box>
    </Box>
  );
};

export default SomuraSlider;
