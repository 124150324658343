import Button from "@mui/material/Button";
import AlarmOnIcon from "@mui/icons-material/AlarmOn";
import { useTranslation } from "react-i18next";

const RemindButton = (props) => {
  const { t } = useTranslation();
  return (
    <Button
      type="submit"
      variant="contained"
      startIcon={<AlarmOnIcon />}
      color="primary"
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {t("Remind again")}
    </Button>
  );
};

export default RemindButton
