import React from "react";

import { Route, Redirect, useLocation } from "react-router-dom";

const AuthenticatedRoute = ({ component: C, props: childProps, ...rest }) => {
  const { pathname, search } = useLocation();
  return (
    <Route
      {...rest}
      render={(props) =>
        childProps.isAuthenticated ? (
          <C {...props} {...childProps} />
        ) : (
          <Redirect to={`/login?redirect=${pathname}${search}`} />
        )
      }
    />
  );
};

export default AuthenticatedRoute;
