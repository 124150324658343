import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { months } from "Constants";
import Collapse from "@mui/material/Collapse";
import { ClickAwayListener } from "@mui/base";
import FormControl from "@mui/material/FormControl";
import { Box } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import theme from "../theme/Theme";

const DaySelector = ({ selectedDay, selectedMonth, ...props }) => {
  const { t } = useTranslation();
  const [openSelection, setOpenSelection] = useState(false);
  const [month, setMonth] = useState(
    isNaN(selectedMonth) || parseInt(selectedMonth) === 0
      ? parseInt(DateTime.now().toFormat("M"))
      : parseInt(selectedMonth)
  );
  const [day, setDay] = useState(
    isNaN(selectedDay) || parseInt(selectedDay) === 0
      ? parseInt(DateTime.now().toFormat("d"))
      : parseInt(selectedDay)
  );
  const [valid, setValid] = useState(true);

  const showThisDate = (check_day) => {
    return (
      ([1, 3, 5, 7, 8, 10, 12].includes(month) && check_day <= 31) ||
      ([4, 6, 9, 11].includes(month) && check_day <= 30) ||
      (month === 2 && check_day <= 29)
    );
  };

  const validateDate = () => {
    // Check if date is possible
    setValid(
      ([1, 3, 5, 7, 8, 10, 12].includes(month) && day <= 31) ||
        ([4, 6, 9, 11].includes(month) && day <= 30) ||
        (month === 2 && day <= 29)
    );
  };

  const Days = () => {
    var days_arr = [];
    var disp_day;
    for (var r = 1; r <= 5; r++) {
      for (var c = 1; c <= 7; c++) {
        disp_day = c + (r - 1) * 7;
        if (showThisDate(disp_day)) {
          days_arr.push(
            <IconButton
              key={disp_day}
              value={disp_day}
              sx={{
                gridRow: r + 1,
                gridColumn: c,
                fontSize: "1rem",
                color:
                  disp_day === parseInt(day)
                    ? "secondary.main"
                    : "white",
              }}
              onClick={handleDayClick}
            >
              {disp_day}
            </IconButton>
          );
        }
      }
    }
    return days_arr;
  };

  const handleClickAway = () => {
    setOpenSelection(false);
  };
  const handleClick = () => {
    setOpenSelection(!openSelection);
  };
  const handleMonthUp = () => {
   setMonth(month === 12 ? 1 : month + 1);
  };
  const handleMonthDown = () => {
    setMonth(month === 1 ? 12 : month - 1);
  };
  const handleDayClick = (event) => {
    const value = parseInt(event.target.value);
    setDay(value);
    setOpenSelection(false);
  };

  useEffect(() => {
    validateDate();
    props.onChange(day + "-" + month);
    // eslint-disable-next-line
  }, [day, month]);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <FormControl
        sx={{ width: props.width || "100%" }}
        disabled={props.disabled}
        required={props.required}
      >
        <Box
          sx={{
            padding: "0 2px",
          }}
        >
          <InputLabel
            htmlFor="day-month-selection"
          >
            {t("Day / month")}
          </InputLabel>
        </Box>
        <OutlinedInput
          id="day-month-selection"
          sx={{
            marginBottom: "4px",
            ...(props.required
              ? {
                  borderRadius: "4px",
                  boxShadow: `inset 0 0 5px ${theme.palette.secondary.main}`,
                }
              : { boxShadow: "inset 1px 1px 3px 1px rgba(0, 0, 0, 0.8)" }),
          }}
          value={
            valid
              ? day +
                ". " +
                t(
                  months.find((M) => parseInt(M.value) === parseInt(month) - 1)
                    ?.label
                )
              : "?"
          }
          variant="outlined"
          size="small"
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="open selection"
                onClick={handleClick}
                disabled={props.disabled}
                edge="end"
              >
                {openSelection ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              </IconButton>
            </InputAdornment>
          }
          inputProps={{
            style: {
              textAlign: "center",
            },
          }}
          required={props.required}
        />
        <Collapse in={openSelection}>
          <Box
            sx={{
              position: "absolute",
              zIndex: "1000",
              width: "100%",
              border: "none",
              borderRadius: "4px",
              boxShadow: "1px 1px 3px 0px rgba(0, 0, 0, 0.4)",
            }}
          >
            <Box
              sx={{
                position: "fixed",
                zIndex: "1000",
                background: "#3E3E3E",
                marginTop: "-4px",
                borderRadius: "6px",
                display: "grid",
                gridTemplateColumns: "repeat(7, 1fr)",
                gridTemplateRows: "repeat(6, 2rem)",
                justifyItems: "center",
                alignItems: "center",
              }}
            >
              <IconButton
                sx={{ gridRow: "1", gridColumn: "1" }}
                onClick={handleMonthDown}
              >
                <ChevronLeftIcon />
              </IconButton>
              <Box sx={{ gridRow: "1", gridColumn: "2 / 7" }}>
                {t(
                  months?.find((M) => M.value === parseInt(month) - 1)?.label
                ) || ""}
              </Box>
              <IconButton
                sx={{ gridRow: "1", gridColumn: "7" }}
                onClick={handleMonthUp}
              >
                <ChevronRightIcon />
              </IconButton>
              {Days()}
            </Box>
          </Box>
        </Collapse>
      </FormControl>
    </ClickAwayListener>
  );
};

export default DaySelector;
