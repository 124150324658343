import {
  FETCH_STATS_SUCCESS,
  FETCH_ABSENCE_STATS_SUCCESS,
} from 'actions/types'

import { userStats, absenceStats } from '../api'

import { dataFailure } from "./general";

const fetchStatsSuccess = (stats) => {
  return {
    type: FETCH_STATS_SUCCESS,
    payload: stats
  }
}

export const fetchStats = (values) => {
  return async (dispatch) => {
    try {
      const statsJSONData = await dispatch(userStats(values))
      await dispatch(fetchStatsSuccess(statsJSONData))
    } catch(err) {
      await dispatch(dataFailure(err.message))
    }
  }
}

const fetchAbsenceStatsSuccess = (stats) => {
  return {
    type: FETCH_ABSENCE_STATS_SUCCESS,
    payload: stats
  }
}

export const fetchAbsenceStats = (values) => {
  return async (dispatch) => {
    try {
      const statsJSONData = await dispatch(absenceStats(values))
      await dispatch(fetchAbsenceStatsSuccess(statsJSONData))
    } catch(err) {
      await dispatch(dataFailure(err.message))
    }
  }
}

