import {
  ADD_PERSON_TIMEPLAN,
  UPDATE_PERSON_TIMEPLAN,
  DELETE_PERSON_TIMEPLAN,
} from "./types";

import {
  personsAddTimePlan,
  personsUpdateTimePlan,
  personsDeleteTimePlan,
} from "../api";

import {
  dataFailure,
  dataSuccess,
} from "./general";

const addTimePlanData = (values) => {
  return {
    type: ADD_PERSON_TIMEPLAN,
    payload: values,
  };
};

export const addPersonTimePlan = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(personsAddTimePlan(values));
      await dispatch(addTimePlanData(values));
      await dispatch(dataSuccess());
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

const updateTimePlanData = (values) => {
  return {
    type: UPDATE_PERSON_TIMEPLAN,
    payload: values,
  };
};

export const updatePersonTimePlan = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(personsUpdateTimePlan(values));
      await dispatch(updateTimePlanData(values));
      await dispatch(dataSuccess());
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

const deleteTimePlanData = (values) => {
  return {
    type: DELETE_PERSON_TIMEPLAN,
    payload: values,
  };
};

export const deletePersonTimePlan = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(personsDeleteTimePlan(values));
      await dispatch(deleteTimePlanData(values));
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};