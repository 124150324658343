import {
  FETCH_UNPUBLISHED_DAYS_SUCCESS
} from 'actions/types'

const initialState = {
  UnpublishedDays: [],
}

const unpublishedDaysReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_UNPUBLISHED_DAYS_SUCCESS:
        return {
          ...state,
          UnpublishedDays: action.payload,
        };
      default: return state;
    }
  }

export default unpublishedDaysReducer