import {
  FETCH_TIMECLOCKS_SUCCESS,
  CREATE_TIMECLOCK,
  UPDATE_TIMECLOCK,
  DELETE_TIMECLOCK,
  SET_SELECTED_TIMECLOCK,
} from "./types";
import {
  fetchTimeClockData,
  addTimeClockData,
  saveTimeClockData,
  deleteTimeClockData,
} from "../api";
import { dataFailure } from "./general";

export const setSelectedTimeclock = (values) => {
  return {
    type: SET_SELECTED_TIMECLOCK,
    payload: values,
  };
};

const fetchTimeClocksSuccess = (values) => {
  return {
    type: FETCH_TIMECLOCKS_SUCCESS,
    payload: values,
  };
};

export const fetchTimeClocks = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(fetchTimeClockData(values));
      await dispatch(fetchTimeClocksSuccess(JSONData));
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

const addTimeclockSuccess = (values) => {
  return {
    type: CREATE_TIMECLOCK,
    payload: values,
  };
};

export const addTimeclock = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(addTimeClockData(values));
      await dispatch(addTimeclockSuccess({ ...values, id: JSONData }));
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

const updateTimeclockSuccess = (values) => {
  return {
    type: UPDATE_TIMECLOCK,
    payload: values,
  };
};

export const updateTimeclock = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(saveTimeClockData(values));
      await dispatch(updateTimeclockSuccess(values));
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

const deleteTimeclockSuccess = (values) => {
  return {
    type: DELETE_TIMECLOCK,
    payload: values,
  };
};

export const deleteTimeclock = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(deleteTimeClockData(values));
      await dispatch(deleteTimeclockSuccess(values));
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};
