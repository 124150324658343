import Button from "@mui/material/Button";
import ClearIcon from "@mui/icons-material/Clear";
import { useTranslation } from "react-i18next";

const CloseButton = (props) => {
  const { t } = useTranslation();
  return (
    <Button
      variant="outlined"
      startIcon={<ClearIcon />}
      color="cancel"
      onClick={props.onClick}
    >
      {t("Close")}
    </Button>
  );
};

export default CloseButton
