import {
  FETCH_DEPARTMENTS_SUCCESS,
  FETCH_DEPARTMENTS_MINIMAL_SUCCESS,
  FETCH_DEPARTMENT,
  ADD_DEPARTMENT,
  UPDATE_DEPARTMENT,
  DELETE_DEPARTMENT,
  RESET_DEPARTMENT,
  NEW_DEPARTMENT,
  SET_DEPARTMENT_ID,
} from "actions/types";

import {
  departments,
  departmentsMinimal,
  departmentsAdd,
  departmentsUpdate,
  departmentsDelete,
} from "../api";
import { dataFailure, dataSuccess } from "./general";

const fetchDepartmentsSuccess = (Departments) => {
  return {
    type: FETCH_DEPARTMENTS_SUCCESS,
    payload: Departments,
  };
};

export const fetchDepartments = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(departments(values));
      await dispatch(fetchDepartmentsSuccess(JSONData));
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

const fetchDepartmentsMinimalSuccess = (Departments) => {
  return {
    type: FETCH_DEPARTMENTS_MINIMAL_SUCCESS,
    payload: Departments,
  };
};

export const fetchDepartmentsMinimal = () => {
  return async (dispatch) => {
    try {
      const departmentsJSONData = await dispatch(departmentsMinimal());
      await dispatch(fetchDepartmentsMinimalSuccess(departmentsJSONData));
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

export const fetchDepartment = (id) => {
  return {
    type: FETCH_DEPARTMENT,
    payload: id,
  };
};

const addDepartmentData = (values) => {
  return {
    type: ADD_DEPARTMENT,
    payload: values,
  };
};

export const addDepartment = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(departmentsAdd(values));
      await dispatch(addDepartmentData(JSONData));
      await dispatch(dataSuccess());
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

const updateDepartmentData = (values) => {
  return {
    type: UPDATE_DEPARTMENT,
    payload: values,
  };
};

export const updateDepartment = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(departmentsUpdate(values));
      await dispatch(updateDepartmentData(values));
      await dispatch(dataSuccess());
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

const deleteDepartmentData = (id) => {
  return {
    type: DELETE_DEPARTMENT,
    payload: id,
  };
};

export const deleteDepartment = (id) => {
  return async (dispatch) => {
    try {
      await dispatch(departmentsDelete(id));
      await dispatch(deleteDepartmentData(id));
      await dispatch(dataSuccess());
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

export const resetDepartment = () => {
  return {
    type: RESET_DEPARTMENT,
  };
};

export const newDepartment = () => {
  return {
    type: NEW_DEPARTMENT,
  };
};

export const setDepartmentId = (id) => {
  return {
    type: SET_DEPARTMENT_ID,
    payload: id,
  };
};
