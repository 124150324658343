import React, { useEffect } from "react";
import { Box } from "@mui/system";
import { DateTime } from "luxon";
const AnalogClock = ({secondsColor}) => {
  function setDate() {
    const secondHand = document.getElementById("secondHand");
    const minuteHand = document.getElementById("minuteHand");
    const hourHand = document.getElementById("hourHand");

    const seconds = DateTime.now().second;
    const minutes = DateTime.now().minute;
    const hours = DateTime.now().hour;
    const secondsDegrees = (seconds / 60) * 360 + 90;
    const minutesDegrees = ((minutes / 60) * 360) + ((seconds / 60) * 6) + 90
    const hoursDegrees = ((hours / 12) * 360) + ((minutes / 60) * 30) + 90

    if(secondHand && minuteHand && hourHand){
      secondHand.style.transform = `rotate(${secondsDegrees}deg)`;
      minuteHand.style.transform = `rotate(${minutesDegrees}deg)`;
      hourHand.style.transform = `rotate(${hoursDegrees}deg)`;
    }
  }

  useEffect(() => {
    const timeRef = setInterval(() => {
      setDate();
    }, 1000);
    return () => {
      clearInterval(timeRef);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <Box
      sx={{
        width: "1.8rem",
        height: "1.8rem",
        position: "relative",
        padding: "2px",
        margin: "0 2px",
        borderRadius: "50%",
        boxShadow: "inset 1px 1px 1px 1px  rgba(0, 0, 0, 0.3)",
      }}
    >
      <Box
        id="hourHand"
        sx={{
          width: "8px",
          right: "50%",
          height: "2px",
          background: "grey",
          position: "absolute",
          top: "50%",
          borderRadius: "6px",
          transformOrigin: "100%",
          transform: "rotate(90deg)",
          transitionTimingFunction: "cubic-bezier(0.1, 2.7, 0.58, 1)",
          zIndex: 3,
        }}
      ></Box>
      <Box
        id="minuteHand"
        sx={{
          width: "12px",
          right: "50%",
          height: "1px",
          background: "grey",
          position: "absolute",
          top: "50%",
          borderRadius: "6px",
          transformOrigin: "100%",
          transform: "rotate(90deg)",
          transitionTimingFunction: "cubic-bezier(0.1, 2.7, 0.58, 1)",
          zIndex: 10,
        }}
      ></Box>
      <Box
        id="secondHand"
        sx={{
          width: "14px",
          right: "50%",
          height: "1px",
          background: secondsColor,
          position: "absolute",
          top: "50%",
          borderRadius: "6px",
          transformOrigin: "100%",
          transform: "rotate(90deg)",
          transitionTimingFunction: "cubic-bezier(0.1, 2.7, 0.58, 1)",
        }}
      ></Box>
    </Box>
  );
};

export default AnalogClock;
