import React from "react";
import { connect } from "react-redux";
import Badge from "@mui/material/Badge";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const mapStateToProps = (state) => {
  const loggedUserId = state.loggeduser.LoggedUser.id;
  const unpublishedDays = state.unpublisheddays.UnpublishedDays;
  return {
    loggedUserId,
    unpublishedDays,
  };
};

function UnpublishedDaysBadge(props) {
  let numUnpublished = 0;
  if (props.unpublishedDays.length > 0) {
    numUnpublished = props.unpublishedDays.reduce(function (prev, act) {
      return prev + parseFloat(act.numDays);
    }, 0);
  }

  return (
    <Badge
      color="error"
      showZero={false}
      badgeContent={numUnpublished}
      sx={{ "& .MuiBadge-badge": { fontSize: "0.8rem" } }}
    >
      <VisibilityOffIcon />
    </Badge>
  );
}

export default connect(mapStateToProps, null)(UnpublishedDaysBadge);
