import React from "react";
import { connect } from "react-redux";
import Badge from "@mui/material/Badge";
import TimeTrackIcon from "@mui/icons-material/TimerOutlined";

const mapStateToProps = (state) => {
  return {
    numFaulty: state.general.Stats.numFaulty
  };
};

function TimeFaultyBadge({ numFaulty }) {
  return (
    <Badge sx={{ "& .MuiBadge-badge": { fontSize: "0.8rem", paddingTop: "2px" }}} color="error" showZero={false} badgeContent={numFaulty} max={99}>
      <TimeTrackIcon
        sx={{
          fontSize: "2rem",
        }}
      />
    </Badge>
  );
}

export default connect(mapStateToProps)(TimeFaultyBadge);
