import {
  FETCH_WEEKLY_TIME_PLANS_SUCCESS,
  CREATE_WEEKLY_TIME_PLAN,
  UPDATE_WEEKLY_TIME_PLAN,
  DELETE_WEEKLY_TIME_PLAN,
  SET_WEEKLY_TIME_PLAN_ID,
  SET_TIMEPLAN_ID
} from "actions/types";

import {
  weeklyTimePlansRead,
  weeklyTimePlanCreate,
  weeklyTimePlanUpdate,
  weeklyTimePlanDelete} from "../api";
import {
  dataFailure,
  dataSuccess,
} from "./general";


const fetchWeeklyTimePlansSuccess = (values) => {
  return {
    type: FETCH_WEEKLY_TIME_PLANS_SUCCESS,
    payload: values,
  };
};

export const fetchWeeklyTimePlans = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(weeklyTimePlansRead(values));
      await dispatch(fetchWeeklyTimePlansSuccess(JSONData));
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

const createWeeklyTimePlanData = (values) => {
  return {
    type: CREATE_WEEKLY_TIME_PLAN,
    payload: values,
  };
};

export const createWeeklyTimePlan = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(weeklyTimePlanCreate(values));
      await dispatch(createWeeklyTimePlanData({ ...values, id: JSONData }));
      await dispatch(dataSuccess());
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

const updateWeeklyTimePlanData = (values) => {
  return {
    type: UPDATE_WEEKLY_TIME_PLAN,
    payload: values,
  };
};

export const updateWeeklyTimePlan = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(weeklyTimePlanUpdate(values));
      await dispatch(updateWeeklyTimePlanData(values));
      await dispatch(dataSuccess());
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};
const deleteWeeklyTimePlanData = (values) => {
  return {
    type: DELETE_WEEKLY_TIME_PLAN,
    payload: values,
  };
};

export const deleteWeeklyTimePlan = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(weeklyTimePlanDelete(values));
      await dispatch(deleteWeeklyTimePlanData({ ...values, id: JSONData }));
      await dispatch(dataSuccess());
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};


export const setWeeklyTimePlanId = (values) => {
  return {
    type: SET_WEEKLY_TIME_PLAN_ID,
    payload: values,
  };
};


export const setTimePlanId = (id) => {
  return {
    type: SET_TIMEPLAN_ID,
    payload: id,
  };
};
