import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { reminderTimes } from "Constants";
import { setReminderAlertTime } from "redux/index";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { Box } from "@mui/material";
import SomuraSelectField from "Components/SomuraSelectField";
import SomuraSubLine from "Components/SomuraSubLine";
import CloseButton from "Components/Buttons/CloseButton";
import RemindButton from "Components/Buttons/RemindButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Draggable from "react-draggable";

import DialogTitle from "Calendars/Components/DialogTitle";

function PaperComponent(props) {
  const nodeRef = useRef(null);
  return (
    <Draggable nodeRef={nodeRef}>
      <Box ref={nodeRef}>
        <Paper {...props} style={{ maxWidth: 700 }} />
      </Box>
    </Draggable>
  );
}

const mapStateToProps = (state) => {
  return {
    reminderDate: state.reminders.showReminderDate,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setReminderAlertTime: (values) => dispatch(setReminderAlertTime(values)),
  };
};

const RemindersAlert = ({
  reminders,
  setReminderAlertTime,
  handleCloseDialog,
}) => {
  const { t } = useTranslation();
  const [state, setState] = useState({
    ...reminders[0],
    startDate: DateTime.fromSQL(reminders[0].startDateTime).toLocaleString(
      DateTime.DATE_SHORT
    ),
    endDate: DateTime.fromSQL(reminders[0].endDateTime).toLocaleString(
      DateTime.DATE_SHORT
    ),
    startTime: DateTime.fromSQL(reminders[0].startDateTime).toFormat("HH:mm"),
    endTime: DateTime.fromSQL(reminders[0].endDateTime).toFormat("HH:mm"),
    alertSent: true,
  });

  const reminderTimeOptions = reminderTimes.map((RT) => ({
    label: RT.number + " " + t(RT.text),
    value: RT.id,
  }));

  const handleClose = () => {
    // Do not alert again
    setReminderAlertTime({
      ...state,
      enableAlert: false,
    });
    handleCloseDialog();
  };

  const handleSubmit = () => {
    const diff = reminderTimes.find(
      (RT) => RT.id === parseInt(state.alertTimeId)
    );
    const newAlert = DateTime.now()
      .plus(diff.interval)
      .toFormat("yyyy-MM-dd HH:mm:ss");
    setReminderAlertTime({
      ...state,
      enableAlert: true,
      alertDateTime: newAlert,
    });
    handleCloseDialog();
  };

  const handleChange = (event) => {
    const name = event.target ? event.target.name : event.name;
    var value = event.target ? event.target.value : event.value;
    setState({ ...state, [name]: value });
  };

  return (
    <Dialog
      sx={{
        minWidth: 500,
        maxWidth: 500,
        margin: "auto",
      }}
      open={true}
      onKeyUp={(e) => {
        if (e.key === "Enter" && !e.shiftKey) {
          handleSubmit();
        }
        if (e.key === "Escape") {
          handleClose();
        }
      }}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      PaperComponent={PaperComponent}
      disableEscapeKeyDown={true}
      aria-labelledby="draggable-selectionform-title"
    >
      <DialogTitle
        id="draggable-selectionform-title"
        dialogTitle={t("Reminder")}
        closeForm={handleClose}
      />
      <DialogContent
        sx={{
          padding: "0px !important",
          backgroundColor: "#F7DDBE",
        }}
      >
        <Box
          sx={{
            padding: "20px 10px 6px 10px",
          }}
        >
          <Grid container spacing={1} sx={{ color: "black" }}>
            <Grid item xs={12}>
              <SomuraSubLine
                sx={{
                  textAlign: "center",
                  marginTop: "-12px",
                  backgroundColor: "#FC8B03",
                  color: "black",
                }}
                noShadow
              >
                {state.startDate === state.endDate
                  ? DateTime.fromSQL(state.startDateTime).toLocaleString(
                      DateTime.DATE_HUGE
                    )
                  : DateTime.fromSQL(state.startDateTime).toLocaleString(
                      DateTime.DATE_MED_WITH_WEEKDAY
                    ) +
                    " - " +
                    DateTime.fromSQL(state.endDateTime).toLocaleString(
                      DateTime.DATE_MED_WITH_WEEKDAY
                    )}
              </SomuraSubLine>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                marginLeft: "4px",
                marginTop: "-12px",
                fontSize: "0.8rem",
                textAlign: "center",
              }}
            >
              {state.isWholeDay ? (
                <Box component="span">{t("All day event")}</Box>
              ) : (
                <Box
                  component="span"
                  sx={{
                    color:
                      DateTime.fromSQL(reminders[0].startDateTime) <
                      DateTime.now()
                        ? "red"
                        : "inherit",
                  }}
                >
                  {DateTime.fromSQL(reminders[0].startDateTime) <
                    DateTime.now() && t("Overdue") + ": "}
                  {t("From") +
                    " " +
                    state.startTime +
                    " " +
                    t("to") +
                    " " +
                    state.endTime}
                </Box>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                textAlign: "center",
                paddingTop: "0px !important",
              }}
            >
              <Box
                component="span"
                sx={{
                  height: "1.8rem",
                  fontSize: "1rem",
                  color: state.categoryColor,
                  backgroundColor: state.categoryBackColor,
                  padding: "0 8px",
                  margin: "0 4px",
                  borderRadius: "0.9rem",
                  border: "1px solid " + state.categoryColor,
                }}
              >
                {state.categoryName}
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                textAlign: "center",
                paddingTop: "0px !important",
              }}
            >
              <Box
                sx={{
                  paddingLeft: "4px",
                  fontWeight: "500",
                }}
                component="span"
              >
                {state.title}
              </Box>
            </Grid>
            <Grid item xs={12} sx={{ marginLeft: "4px", marginBottom: "12px" }}>
              <Box component="span">{state.description}</Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  fontSize: "1rem",
                  marginLeft: "2px",
                  marginBottom: "-6px",
                }}
              >
                {t("Remind again in")}:
              </Box>
              <SomuraSelectField
                name="alertTimeId"
                options={reminderTimeOptions}
                sx={{ marginTop: "4px", backgroundColor: "#FC8B03" }}
                disabled={!state.enableAlert}
                value={state.alertTimeId}
                onChange={handleChange}
                variant="outlined"
                size="small"
                fullWidth
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          padding: "4px 10px !important",
          backgroundColor: "dialog.bottom",
        }}
      >
        <CloseButton onClick={handleClose} />
        <RemindButton onClick={handleSubmit} />
      </DialogActions>
    </Dialog>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(RemindersAlert);
