import { createTheme } from "@mui/material/styles";
import { colors } from "@mui/material";
import { deDE} from "@mui/x-data-grid";
import { deDE as coredeDE } from "@mui/material/locale";

const white = "#FFFFFF";
const black = "#000000";
const somurablue = "#243573";

const mode = window.localStorage.getItem("theme") || "dark";

const theme = createTheme(
  {
    palette: {
      mode: mode,
      primary: {
        contrastText: white,
        dark: colors.indigo[800],
        main: somurablue,
        light: colors.indigo[100],
      },
      secondary: {
        contrastText: mode === "dark" ? white : black,
        dark: mode === "dark" ? colors.lightBlue[900] : colors.orange[900],
        main:
          mode === "dark" ? colors.lightBlue["A400"] : colors.orange["A400"],
        light:
          mode === "dark" ? colors.lightBlue["A400"] : colors.orange["A400"],
      },
      edit: {
        contrastText: white,
        main: colors.orange[800],
      },
      cancel: {
        main: mode === "dark" ? colors.grey[200] : colors.grey[600],
      },
      delete: {
        contrastText: white,
        main: colors.red[800],
      },
      text: {
        main: mode === "dark" ? white : black,
        screenTitle: mode === "dark" ? "#9CDCFE" : somurablue,
        highlight: mode === "dark" ? white : black,
        neutral: mode === "dark" ? white : black,
        disabled:
          mode === "dark" ? "rgba(255, 255, 255, 0.5)" : "rgba(0, 0, 0, 0.5)",
        calendars: colors.grey[900],
        link: mode === "dark" ? "#ADBDE3" : colors.blue[600],
        important: mode === "dark" ? colors.amber["A400"] : colors.red["A700"],
      },
      button: {
        cancel: colors.grey[300],
      },
      dialog: {
        title: mode === "dark" ? colors.grey[700] : colors.grey[400],
        titleText: mode === "dark" ? white : black,
        content: mode === "dark" ? colors.grey[700] : colors.grey[400],
        bottom: "#424242",
      },
      background: {
        default: mode === "dark" ? colors.grey[900] : colors.grey[200],
        contrastText: mode === "dark" ? white : black,
        drawer: mode === "dark" ? colors.grey[800] : white,
        form: mode === "dark" ? colors.grey[900] : colors.grey[100],
        paperBottom: mode === "dark" ? colors.grey[700] : colors.grey[200],
        highlight: mode === "dark" ? colors.grey[600] : colors.grey[500],
        calendarDayFrame: mode === "dark" ? colors.grey[300] : colors.grey[200],
        body: mode === "dark" ? "#303030" : white,
        table: mode === "dark" ? "#5D5D5D" : "#EFEFEF",
        accordion: mode === "dark" ? "#616161" : "#EFEFEF",
        message: mode === "dark" ? "#37474F" : "#D0D0D0",
        messageList: mode === "dark" ? "#222222" : "#D0D0D0",
        messageListItem: mode === "dark" ? "#3E3E3E" : "#989898",
        messageListHighlight: colors.grey[600],
        scrollBar: colors.grey[400],
        scrollBarThumb: colors.grey[600],
        required: colors.grey[600],
        listItem: mode === "dark" ? colors.grey[800] : colors.grey[200],
        statsBar: mode === "dark" ? colors.grey[600] : colors.grey[400],
      },
      dashBoard: {
        title: mode === "dark" ? colors.grey[500] : colors.grey[500],
        number: mode === "dark" ? colors.grey[300] : colors.grey[600],
        paper: mode === "dark" ? colors.grey[700] : white,
      },
      somura: somurablue,
      disabled: colors.grey[500],
      dayFrame: mode === "dark" ? "#FFFFFF" : "#000000",
      red: mode === "dark" ? colors.red[500] : colors.red[800],
      redonblack: "orangered",
      green: mode === "dark" ? colors.green[400] : colors.green[800],
      lightgreen: colors.green[400],
      orange: "#AE690F",
      lightblue: "#677FBD",
      yellow: "#FFCA00",
      blue: colors.indigo[500],
      verylightblue: "#ADBDE3",
      lightgrey: colors.grey[600]
    },
    overrides: {
      MuiInputLabel: {
        // Name of the component ⚛️ / style sheet
        root: {
          // Name of the rule
          // color: "orange",
          "&$focused": {
            // increase the specificity for the pseudo class
            color: white,
          },
        },
      },
    },
  },
  deDE,
  coredeDE
);

export default theme;
