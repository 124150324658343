import React from "react";
import { Box } from "@mui/material";

const SomuraSubLine = ({ children, ...props }) => {
  return (
    <Box
      sx={{
        fontSize: props.raised ? "0.9rem" : "1rem",
        height: "1.5rem",
        lineHeight: props.raised ? "1.4rem " : "1.6rem",
        paddingLeft: "1rem",
        marginBottom: "6px",
        color: "text.screenTitle",
        backgroundColor: "background.paperBottom",
        borderRadius: "5px",
        boxShadow: props.noShadow
          ? "none"
          : props.raised
          ? "inset -1px -1px 3px 3px rgba(0, 0, 0, 0.8)"
          : "inset 1px 1px 3px 1px rgba(0, 0, 0, 0.8)",
        textAlign: props.center ? "center" : "left",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        ...props.sx,
      }}
    >
      {children}
    </Box>
  );
};

export default SomuraSubLine;
