import React from "react";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";

const SomuraSwitchField = ({
  labelLeft,
  labelRight,
  name,
  disabled,
  value,
  ...props
}) => {
  return (
    <FormControlLabel
      sx={{
        ...props.sx,
        "> span": {
          fontStyle: props.checked ? "normal" : "italic",
          color: props.checked ? "text.main" : "text.disabled",
        },
      }}
      label={props.label}
      labelPlacement="end"
      disabled={props.disabled}
      control={
        <Switch
          value={props.checked ? "on" : "off"}
          disabled={disabled}
          checked={props.checked}
          onChange={props.onChange}
          name={name}
          color="secondary"
        />
      }
    />
  );
};

export default SomuraSwitchField;
