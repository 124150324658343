import {
  SEND_MAIL,
} from "actions/types";

import {
  sendmail
} from "../api";

import { dataFailure, dataSuccess } from "./general";

const mailSent = (values) => {
  return {
    type: SEND_MAIL,
    payload: values,
  };
};

export const sendMail = (values) => {
  return async (dispatch) => {
    try {
     const JSONData = await dispatch(sendmail(values));
      dispatch(mailSent(JSONData));
      dispatch(dataSuccess());
    } catch (err) {
      dispatch(dataFailure());
    }
  };
};