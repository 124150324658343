import {
  FETCH_COMPANIES_SUCCESS,
  COMPANIES_FAILURE,
  SAVE_COMPANIES,
  DELETE_COMPANIES,
} from "actions/types";
import { updateArrayItem } from "utils";

const initialState = {
  Companies: [],
  error: "",
};

const companiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COMPANIES_SUCCESS:
      return {
        ...state,
        Companies: action.payload,
        error: "",
      }
    case COMPANIES_FAILURE:
      return {
        ...state,
        error: action.payload,
      }
    case SAVE_COMPANIES:
      return {
        ...state,
        selectedCompanyId: -1,
        Companies: action.payload.id === 0 ?
          [...state.Companies, action.payload] :
          updateArrayItem(state.Companies, state.Companies.findIndex((C) => C.id === action.payload.id), action.payload)
      }
    case DELETE_COMPANIES:
      return {
        ...state,
        selectedCompanyId: -1,
        Companies: state.Companies.splice(state.Companies.findIndex((C) => C.id === action.payload.id), 1)
      }
    default:
      return state;
  };
}
    

export default companiesReducer;
