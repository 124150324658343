import React from "react";
import { connect } from "react-redux";
import Badge from "@mui/material/Badge";
import MailIcon from "@mui/icons-material/Mail";

const mapStateToProps = (state) => {
  return {
    unread: state.general.Stats.numUnread
  };
};

function NewsBadge({ unread }) {
  // Change favicon
  var favLink = document.querySelector("link[rel~='icon']");
  var touchLink = document.querySelector("link[rel~='apple-touch-icon']");
  if (unread > 0) {
    favLink.href = "../images/somura-favicon_light_mail.svg";
    touchLink.href = "../images/somura-favicon_light_mail.svg";
    document.title = "Somura (" + unread + ")";
  } else {
    favLink.href = "../images/somura-favicon_light_512.svg";
    touchLink.href = "../images/somura-favicon_light_512.svg";
    document.title = "Somura";
  }

  return (
    <Badge
      sx={{ "& .MuiBadge-badge": { fontSize: "0.8rem" } }}
      color="error"
      showZero={false}
      badgeContent={unread}
      max={99}
    >
      <MailIcon
        sx={{
          fontSize: "2rem",
        }}
      />
    </Badge>
  );
}

export default connect(mapStateToProps)(NewsBadge);
