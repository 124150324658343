import React, { useEffect, useState, useRef, Fragment } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { store } from "redux/store";
import { DateTime } from "luxon";
import {
  publishOffDays,
  fetchOffDayTypes,
  fetchUnpublishedDays,
  fetchYear,
} from "redux/index";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Draggable from "react-draggable";

import Paper from "@mui/material/Paper";
import { Box } from "@mui/system";

import DialogTitle from "Calendars/Components/DialogTitle";
import { Button } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";

import VisibilityIcon from "@mui/icons-material/Visibility";
import ClearIcon from "@mui/icons-material/Clear";

function PaperComponent(props) {
  const nodeRef = useRef(null);
  return (
    <Draggable nodeRef={nodeRef}>
      <Box ref={nodeRef}>
        <Paper {...props} style={{ maxWidth: 700 }} />
      </Box>
    </Draggable>
  );
}

const mapStateToProps = (state) => {
  return {
    displayyear: state.general.DisplayYear,
    selectedPersonId: state.selections.selectedUserId,
    logged: state.loggeduser.LoggedUser.id,
    loginState: state.loggeduser.LoginState,
    offDayTypes: state.offdaytypes.OffDayTypes,
    unpublishedDays: state.unpublisheddays.UnpublishedDays,
    dataSuccess: state.general.dataSuccess,
    selectedLocation: state.selections.selectedLocationId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    publishOffDays: (values) =>
      dispatch(publishOffDays({ approvementId: values })),
    fetchOffDayTypes: (values) => dispatch(fetchOffDayTypes(values)),
    fetchUnpublishedDays: async (id) =>
      dispatch(fetchUnpublishedDays({ userId: id })),
    fetchYear: async (values) => dispatch(fetchYear(values)),
  };
};

const UnpublishedDays = ({ offDayTypes, selectedLocation, ...props }) => {
  const { t } = useTranslation();
  const language = localStorage.getItem("lang");

  const [checked, setChecked] = useState([]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const findOffDayTypeName = (id, language) => {
      const type = offDayTypes.find((O) => O.id === id);
      if (type) {
        const name = type.offDayType;
        return name;
      }
  };

  const DayList = () => {
    let items = [];
    let UD;
    let UId;
    let displayDate;
    let displayDays;
    for (var i = 0; i < props.unpublishedDays.length; i++) {
      UD = props.unpublishedDays[i];
      UId = UD.approvementId;
      displayDate = DateTime.fromSQL(UD.startDate).toLocaleString(DateTime.DATE_HUGE)
      displayDays = UD.numDays;
      if (UD.numDays !== "1.0") {
        displayDate +=
          " " +
          t("to") +
          " " +
          DateTime.fromSQL(UD.endDate).toLocaleString(DateTime.DATE_HUGE);
        displayDays += " " + t("days") + " ";
      } else {
        displayDays += " " + t("day") + " ";
      }
      displayDays += findOffDayTypeName(UD.offDayTypeId, language);
      const labelId = `checkbox-list-label-${UId}`;
      items.push(
        <ListItem
          key={UId}
          role={undefined}
          dense
          button
          onClick={handleToggle(UId)}
        >
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={checked.indexOf(UId) !== -1}
              tabIndex={-1}
              disableRipple
              inputProps={{ "aria-labelledby": labelId }}
              color="secondary"
            />
          </ListItemIcon>
          <ListItemText
            id={labelId}
            primary={
              <Fragment>
                <Box
                  sx={{
                    fontSize: "1rem",
                  }}
                >
                  {displayDate}
                </Box>
                <Box
                  sx={{
                    fontSize: "0.8rem",
                  }}
                >
                  {displayDays}
                </Box>
              </Fragment>
            }
          />
        </ListItem>
      );
    }
    return <List>{items}</List>;
  };

  const handleSubmit = (values) => {
    for (var i = 0; i < checked.length; i++) {
      props.publishOffDays(checked[i]);
    }
    props.fetchUnpublishedDays(props.logged);
    store.dispatch({ type: "DATA_SUCCESS" });
    props.closeUnpublishedDays();
    props.fetchYear({
      year: props.displayyear,
      userId: props.selectedPersonId,
      locationId: selectedLocation,
    });
  };

  const handleCancel = () => {
    props.closeUnpublishedDays();
  };

  useEffect(() => {
    props.fetchOffDayTypes({ locationId: selectedLocation });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocation]);

  return (
    <Dialog
      sx={{
        overflowY: "hidden",
      }}
      open={true}
      onKeyUp={(e) => {
        if (e.key === "Enter" && !e.shiftKey) {
          handleCancel();
        }
        if (e.key === "Escape") {
          handleCancel();
        }
      }}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleCancel();
        }
      }}
      PaperComponent={PaperComponent}
      disableEscapeKeyDown={true}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle
        dialogTitle={t("Unpublished days")}
        closeForm={handleCancel}
      />
      <DialogContent
        sx={{
          padding: "0px !important",
          backgroundColor: "dialog.content",
        }}
        style={{ padding: "0px" }}
      >
        <div>{DayList()}</div>
      </DialogContent>
      <DialogActions
        sx={{
          padding: "4px 10px !important",
          backgroundColor: "dialog.bottom",
        }}
      >
        <Button
          variant="outlined"
          color="cancel"
          startIcon={<ClearIcon />}
          onClick={handleCancel}
        >
          {t("Abort")}
        </Button>
        <Button
          startIcon={<VisibilityIcon />}
          color="primary"
          variant="contained"
          onClick={handleSubmit}
          disabled={checked.length === 0}
        >
          {t("Publish")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(UnpublishedDays);
