import React, { useEffect } from "react";
import preval from "preval.macro";
import { connect } from "react-redux";
import { DateTime } from "luxon";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { fetchCurrentVersions } from "redux/index";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const mapStateToProps = (state) => {
  return {
    versions: state.general.Versions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCurrentVersions: () => dispatch(fetchCurrentVersions()),
  };
};

const AboutSomura = ({ closeAbout, versions, fetchCurrentVersions }) => {
  const { t } = useTranslation();

  const buildTimestamp = preval`module.exports = new Date().getTime();`;
  const buildDateTime = DateTime.fromMillis(buildTimestamp).toLocaleString(
    DateTime.DATETIME_SHORT_WITH_SECONDS
  );
  const buildYear = DateTime.fromMillis(buildTimestamp).year;

  useEffect(() => {
    fetchCurrentVersions();
    //eslint-disable-next-line
  }, []);

  return (
    <div>
      <Dialog
        open
        onKeyUp={(e) => {
          if (e.key === "Enter" && !e.shiftKey) {
            closeAbout();
          }
          if (e.key === "Escape") {
            closeAbout();
          }
        }}
        onClose={closeAbout}
      >
        <DialogTitle
          sx={{
            backgroundColor: "background.scrollBar",
          }}
        >
          <Box
            component="img"
            sx={{
              width: "50%",
              display: "flex",
              margin: "auto",
              marginBottom: "1rem",
            }}
            alt="Logo"
            src={"/images/Somura-logo-default.svg"}
          />
        </DialogTitle>
        <DialogContent
          sx={{
            backgroundColor: "background.scrollBar",
          }}
        >
          <DialogContentText
            sx={{
              color: "text.calendars",
            }}
          >
            {"PHP: " + versions.PHP}
          </DialogContentText>
          <DialogContentText
            sx={{
              color: "text.calendars",
            }}
          >
            {"MySQL: " + versions.MySQL}
          </DialogContentText>
          <DialogContentText
            sx={{
              color: "text.calendars",
            }}
          >
            {t("Build date")}: {buildDateTime}
          </DialogContentText>
          <DialogContentText
            sx={{
              color: "text.calendars",
            }}
          >
            &copy; {buildYear} NETitwork GmbH. All rights reserved
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            backgroundColor: "background.scrollBar",
          }}
        >
          <Button color="primary" variant="contained" onClick={closeAbout}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AboutSomura);
