import {
  FETCH_INDUSTRIES_SUCCESS,
  SAVE_INDUSTRY,
  DELETE_INDUSTRY,
} from "actions/types";

import { industriesRead, industrySave, industryDelete } from "../api";
import { dataFailure, dataSuccess } from "./general";

const fetchIndustriesSuccess = (Industries) => {
  return {
    type: FETCH_INDUSTRIES_SUCCESS,
    payload: Industries,
  };
};

export const fetchIndustries = () => {
  return async (dispatch) => {
    try {
      const industriesJSONData = await dispatch(industriesRead());
      await dispatch(fetchIndustriesSuccess(industriesJSONData));
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

const saveIndustryData = (values) => {
  return {
    type: SAVE_INDUSTRY,
    payload: values,
  };
};

export const saveIndustry = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(industrySave(values));
      await dispatch(saveIndustryData(values));
      await dispatch(dataSuccess());
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

const deleteIndustryData = (values) => {
  return {
    type: DELETE_INDUSTRY,
    payload: values,
  };
};

export const deleteIndustry = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(industryDelete(values));
      await dispatch(deleteIndustryData(values));
      await dispatch(dataSuccess());
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};