import {
  FETCH_EXCLUDED_RECURRING_ABSENCES_SUCCESS,
  CREATE_EXCLUDED_RECURRING_ABSENCE,
  DELETE_EXCLUDED_RECURRING_ABSENCES,
} from "actions/types";

import {
  recurringAbsenceExclusionsRead,
  recurringAbsenceExclusionCreate,
  recurringAbsenceExclusionsDelete,
} from "../api";
import {
  dataFailure,
  dataSuccess,
} from "./general";


const fetchRecurringAbsenceExclusionsSuccess = (values) => {
  return {
    type: FETCH_EXCLUDED_RECURRING_ABSENCES_SUCCESS,
    payload: values,
  };
};

export const fetchRecurringAbsenceExclusions = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(recurringAbsenceExclusionsRead(values));
      await dispatch(fetchRecurringAbsenceExclusionsSuccess(JSONData));
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

const createRecurringAbsenceExclusionData = (values) => {
  return {
    type: CREATE_EXCLUDED_RECURRING_ABSENCE,
    payload: values,
  };
};

export const createRecurringAbsenceExclusion = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(recurringAbsenceExclusionCreate(values));
      await dispatch(createRecurringAbsenceExclusionData({ ...values, id: JSONData }));
      await dispatch(dataSuccess());
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

const deleteRecurringAbsenceExclusionsData = (values) => {
  return {
    type: DELETE_EXCLUDED_RECURRING_ABSENCES,
    payload: values,
  };
};

export const deleteRecurringAbsenceExclusions = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(recurringAbsenceExclusionsDelete(values));
      await dispatch(deleteRecurringAbsenceExclusionsData({ ...values, id: JSONData }));
      await dispatch(dataSuccess());
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};
