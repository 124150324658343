import { Box } from "@mui/material";

const ColorTextItem = (props) => {
  return (
    <Box
      sx={{
        width: "100%",
        padding: "4px",
        fontSize: "1rem",
        display: "grid",
        gridTemplateColumns: "2rem 1fr",
        gridTemplateRows: "1rem",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          gridRow: 1,
          gridColumn: 1,
          width: "12rem",
          height: "1.5rem",
          border: "1px solid grey",
          borderRadius: "0.75rem",
          backgroundColor: props.backColor,
        }}
      >
        <Box
          sx={{
            gridRow: 1,
            gridColumn: 2,
            marginLeft: "6px",
            color: props.color,
          }}
        >
          {props.text}
        </Box>
      </Box>
    </Box>
  );
};

export default ColorTextItem;
