import {
  FETCH_RECURRING_ABSENCES_SUCCESS,
  CREATE_RECURRING_ABSENCE,
  UPDATE_RECURRING_ABSENCE,
  DELETE_RECURRING_ABSENCE,
  SET_RECURRING_ABSENCE_ID,
  SPLIT_RECURRING_ABSENCE_SUCCESS
} from "actions/types";

import {
  recurringAbsencesRead,
  recurringAbsenceCreate,
  recurringAbsenceUpdate,
  recurringAbsenceDelete,
  recurringAbsenceSplit,
} from "../api";
import {
  dataFailure,
  dataSuccess,
  actionSuccess,
} from "./general";

const fetchRecurringAbsencesSuccess = (values) => {
  return {
    type: FETCH_RECURRING_ABSENCES_SUCCESS,
    payload: values,
  };
};

export const fetchRecurringAbsences = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(recurringAbsencesRead(values));
      await dispatch(fetchRecurringAbsencesSuccess(JSONData));
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

const createRecurringAbsenceData = (values) => {
  return {
    type: CREATE_RECURRING_ABSENCE,
    payload: values,
  };
};

export const createRecurringAbsence = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(recurringAbsenceCreate(values));
      await dispatch(createRecurringAbsenceData({ ...values, id: JSONData }));
      await dispatch(dataSuccess());
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

const updateRecurringAbsenceData = (values) => {
  return {
    type: UPDATE_RECURRING_ABSENCE,
    payload: values,
  };
};

export const updateRecurringAbsence = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(recurringAbsenceUpdate(values));
      await dispatch(updateRecurringAbsenceData(values));
      await dispatch(dataSuccess());
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};
const deleteRecurringAbsenceData = (values) => {
  return {
    type: DELETE_RECURRING_ABSENCE,
    payload: values,
  };
};

export const deleteRecurringAbsence = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(recurringAbsenceDelete(values));
      await dispatch(deleteRecurringAbsenceData({ ...values, id: JSONData }));
      await dispatch(dataSuccess());
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

const splitRecurringAbsenceSuccess = (values) => {
  return {
    type: SPLIT_RECURRING_ABSENCE_SUCCESS,
    payload: values,
  };
};

export const splitRecurringAbsence = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(recurringAbsenceSplit(values));
      await dispatch(splitRecurringAbsenceSuccess());
      dispatch(actionSuccess());
    } catch (err) {
      await dispatch(dataFailure("times: monthTermination " + err.message));
    }
  };
};

export const setRecurringAbsenceId = (values) => {
  return {
    type: SET_RECURRING_ABSENCE_ID,
    payload: values,
  };
};
