import {
  FETCH_SPECIALDAYS_SUCCESS,
  SET_SPECIALDAY_ID,
  ADD_SPECIALDAY,
  UPDATE_SPECIALDAY,
  DELETE_SPECIALDAY,
  FETCH_REMARKABLEDAYS_SUCCESS,
  SET_REMARKABLEDAY_ID,
  UPDATE_REMARKABLEDAY,
  FETCH_HOLIDAYS_SUCCESS,
  UPDATE_HOLIDAY,
  ADD_HOLIDAY,
  DELETE_HOLIDAY,
  SET_DAYTYPE_ID,
  SET_HOLIDAYTYPE_ID,
  CREATE_DAYTYPE,
  UPDATE_DAYTYPE,
  DELETE_DAYTYPE,
  SET_HOLIDAY_ID,
  FETCH_DAYTYPES_SUCCESS,
} from "actions/types";

import { 
  specialDays, 
  specialDayCreate,
  specialDayUpdate,
  specialDayDelete,
  remarkableDays,
  remarkableDayUpdate,
  holidays,
  holidayUpdate,
  holidayAdd,
  holidayDelete,
  daytypeCreate,
  daytypeUpdate,
  daytypeDelete,
  daytypes
} from '../api'
import {
  dataFailure,
  dataSuccess,
} from "./general";

const fetchSpecialDaysSuccess = (specialDays) => {
  return {
    type: FETCH_SPECIALDAYS_SUCCESS,
    payload: specialDays
  }
}

export const fetchSpecialDays = () => {
  return async (dispatch) => {
    try {
      const specialDaysJSONData = await dispatch(specialDays())
      await dispatch(fetchSpecialDaysSuccess(specialDaysJSONData))
    } catch(err) {
      await dispatch(dataFailure(err.message))
    }
  }
}

export const setSpecialDayId = (id) => {
  return {
    type: SET_SPECIALDAY_ID,
    payload: id
  }
}

const fetchRemarkableDaysSuccess = (remarkableDays) => {
  return {
    type: FETCH_REMARKABLEDAYS_SUCCESS,
    payload: remarkableDays
  }
}

export const fetchRemarkableDays = () => {
  return async (dispatch) => {
    try {
      const remarkableDaysJSONData = await dispatch(remarkableDays())
      await dispatch(fetchRemarkableDaysSuccess(remarkableDaysJSONData))
    } catch(err) {
      await dispatch(dataFailure(err.message))
    }
  }
}

export const setRemarkableDayId = (id) => {
  return {
    type: SET_REMARKABLEDAY_ID,
    payload: id
  }
}

const updateRemarkableDayData = (values) => {
  return {
    type: UPDATE_REMARKABLEDAY,
    payload: values
  }
}

export const updateRemarkableDay = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(remarkableDayUpdate(values));
      await dispatch(updateRemarkableDayData(values))
      await dispatch(dataSuccess())
    } catch(err) {
      console.error('failed to update remarkable days data', err); // eslint-disable-line no-console
      await dispatch(dataFailure(err.message))
      
    }
  }
}

const addSpecialDayData = (values) => {
  return {
    type: ADD_SPECIALDAY,
    payload: values
  }
}

export const addSpecialDay = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(specialDayCreate(values));
      await dispatch(addSpecialDayData(JSONData))
      await dispatch(dataSuccess())
    } catch(err) {
      await dispatch(dataFailure(err.message))
      
    }
  }
}

const updateSpecialDayData = (values) => {
  return {
    type: UPDATE_SPECIALDAY,
    payload: values
  }
}

export const updateSpecialDay = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(specialDayUpdate(values));
      await dispatch(updateSpecialDayData(values))
      await dispatch(dataSuccess())
    } catch(err) {
      await dispatch(dataFailure(err.message))
      
    }
  }
}

const deleteSpecialDayData = (values) => {
  return {
    type: DELETE_SPECIALDAY,
    payload: values
  }
}

export const deleteSpecialDay = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(specialDayDelete(values));
      await dispatch(deleteSpecialDayData(values))
      await dispatch(dataSuccess())
    } catch(err) {
      await dispatch(dataFailure(err.message))
      
    }
  }
}

const fetchHolidaysSuccess = (holidays) => {
  return {
    type: FETCH_HOLIDAYS_SUCCESS,
    payload: holidays
  }
}

export const fetchHolidays = (values) => {
  return async (dispatch) => {
    try {
      const holidaysJSONData = await dispatch(holidays(values))
      await dispatch(fetchHolidaysSuccess(holidaysJSONData))
    } catch(err) {
      await dispatch(dataFailure(err.message))
    }
  }
}

export const setHolidayId = (id) => {
  return {
    type: SET_HOLIDAY_ID,
    payload: id
  }
}

const updateHolidayData = (values) => {
  return {
    type: UPDATE_HOLIDAY,
    payload: values
  }
}

export const updateHoliday = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(holidayUpdate(values));
      await dispatch(updateHolidayData(values))
      await dispatch(dataSuccess())
    } catch(err) {
      console.error('failed to update holidays data', err); // eslint-disable-line no-console
      await dispatch(dataFailure(err.message))
      
    }
  }
}

const addHolidayData = (values) => {
  return {
    type: ADD_HOLIDAY,
    payload: values
  }
}

export const addHoliday = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(holidayAdd(values))
      await dispatch(addHolidayData(values))
      await dispatch(dataSuccess())
    } catch(err) {
      await dispatch(dataFailure(err.message))
      
    }
  }
}

const deleteHolidayData = (id) => {
  return {
    type: DELETE_HOLIDAY,
    payload: id
  }
}

export const deleteHoliday = (id) => {
  return async (dispatch) => {
    try {
      await dispatch(holidayDelete(id));
      await dispatch(deleteHolidayData(id))
      await dispatch(dataSuccess())
    } catch(err) {
      console.error('failed to delete holiday data', err); // eslint-disable-line no-console
      await dispatch(dataFailure(err.message))
      
    }
  }
}

export const setDayTypeId = (id) => {
  return {
    type: SET_DAYTYPE_ID,
    payload: id,
  };
};

export const setHolidayTypeId = (id) => {
  return {
    type: SET_HOLIDAYTYPE_ID,
    payload: id,
  };
};

const createDayTypeData = (values) => {
  return {
    type: CREATE_DAYTYPE,
    payload: values
  }
}

export const createDayType = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(daytypeCreate(values))
      await dispatch(createDayTypeData(JSONData));
    } catch(err) {
      await dispatch(dataFailure(err.message))
    }
  }
}

const updateDayTypeData = (values) => {
  return {
    type: UPDATE_DAYTYPE,
    payload: values
  }
}

export const updateDayType = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(daytypeUpdate(values))
      await dispatch(updateDayTypeData(JSONData));
    } catch(err) {
      await dispatch(dataFailure(err.message))
    }
  }
}

const deleteDayTypeData = (values) => {
  return {
    type: DELETE_DAYTYPE,
    payload: values
  }
}

export const deleteDayType = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(daytypeDelete(values))
      await dispatch(deleteDayTypeData(JSONData));
    } catch(err) {
      await dispatch(dataFailure(err.message))
    }
  }
}

const fetchDayTypesSuccess = (holidaytypes) => {
  return {
    type: FETCH_DAYTYPES_SUCCESS,
    payload: holidaytypes,
  };
};

export const fetchDayTypes = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(daytypes(values));
      await dispatch(fetchDayTypesSuccess(JSONData));
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

