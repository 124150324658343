import {
  FETCH_OFFDAYTYPES_SUCCESS,
  UPDATE_OFFDAYTYPE,
  ADD_OFFDAYTYPE,
  UPDATE_STANDARD_DISPLAYSETTINGS,
  DELETE_OFFDAYTYPE,
  SET_OFFDAYTYPE_ID,
  SAVE_OFFDAYTYPENAME
} from "actions/types";

import {
  offDayTypes,
  offDayTypeUpdate,
  offDayTypeAdd,
  offDayTypeDelete,
  saveOffDayTypeNames,
} from "../api";
import {
  dataFailure,
  dataSuccess,
} from "./general";

const fetchOffDayTypesSuccess = (offDayTypes) => {
  return {
    type: FETCH_OFFDAYTYPES_SUCCESS,
    payload: offDayTypes
  }
}

export const fetchOffDayTypes = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(offDayTypes(values))
      await dispatch(fetchOffDayTypesSuccess(JSONData))
    } catch(err) {
      await dispatch(dataFailure(err.message))
    }
  }
}

export const setOffDayTypeId = (id) => {
  return {
    type: SET_OFFDAYTYPE_ID,
    payload: id
  }
}

const updateOffDayTypeData = (values) => {
  return {
    type: UPDATE_OFFDAYTYPE,
    payload: values
  }
}

const updateStandardDisplaySettings = (values) => {
  return {
    type: UPDATE_STANDARD_DISPLAYSETTINGS,
    payload: values
  }
} 

export const updateOffDayType = (values) => {
  return async (dispatch) => {
    try {
      const responseJSON = await dispatch(offDayTypeUpdate(values));
      await dispatch(updateStandardDisplaySettings(responseJSON));
      await dispatch(updateOffDayTypeData(responseJSON));
      await dispatch(dataSuccess())
    } catch(err) {
      console.error('failed to update offday type', err); // eslint-disable-line no-console
      await dispatch(dataFailure(err.message));
      
    }
  }
}

const addOffDayTypesData = (values) => {
  return {
    type: ADD_OFFDAYTYPE,
    payload: values
  }
}

export const addOffDayType = (values) => {
  return async (dispatch) => {
    try {
      const responseJSON = await dispatch(offDayTypeAdd(values))
      await dispatch(addOffDayTypesData(responseJSON))
      await dispatch(dataSuccess())
    } catch(err) {
      await dispatch(dataFailure(err.message))
      
    }
  }
}

const deleteOffDayTypeData = (id) => {
  return {
    type: DELETE_OFFDAYTYPE,
    payload: id
  }
}

export const deleteOffDayType = (id) => {
  return async (dispatch) => {
    try {
      await dispatch(offDayTypeDelete(id));
      await dispatch(deleteOffDayTypeData(id))
      await dispatch(dataSuccess())
    } catch(err) {
      console.error('failed to delete offday type data', err); // eslint-disable-line no-console
      await dispatch(dataFailure(err.message))
      
    }
  }
}

const saveOffDayTypeNamesSuccess = (offDayTypeNames) => {
  return {
    type: SAVE_OFFDAYTYPENAME,
    payload: offDayTypeNames,
  };
};

export const saveOffDayTypeName = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(saveOffDayTypeNames(values));
      await dispatch(saveOffDayTypeNamesSuccess(values));
      await dispatch(dataSuccess());
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};