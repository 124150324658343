import {
  SET_OFFDAYS_SUCCESS,
  DELETE_OFFDAYS,
  REQUEST_OFFDAYS,
  PUBLISH_OFFDAYS,
  ACCEPT_OFFDAYS,
  DECLINE_OFFDAYS,
  SET_OFFDAYS_FAILURE,
  SET_APPROVEMENT_STATE
} from 'actions/types'

import { setUserOffDays, deleteUserOffDays, requestUserOffDays, publishUserOffDays, acceptUserOffDays, declineUserOffDays, setApprovementState } from '../api'
import { dataFailure, dataSuccess } from './general'

const setOffDaysSuccess = (values) => {
  return {
    type: SET_OFFDAYS_SUCCESS,
    payload: values
  }
}

const setOffDaysFailure = (err) => {
  return {
    type: SET_OFFDAYS_FAILURE,
    payload: err
  }
}

export const setOffDays = (values) => {
  return (dispatch) => {
    try {
      dispatch(setUserOffDays(values))
      dispatch(setOffDaysSuccess(values))
      dispatch(dataSuccess())
    } catch(err) {
      dispatch(setOffDaysFailure(err))
      dispatch(dataFailure())
    }
  }
}

const deleteOffDaysData = (approvementId) => {
  return {
    type: DELETE_OFFDAYS,
    payload: approvementId
  }
}

export const deleteOffDays = (approvementId) => {
  return (dispatch) => {
    try {
      dispatch(deleteUserOffDays(approvementId));
      dispatch(deleteOffDaysData(approvementId))
      dispatch(dataSuccess())
    } catch(err) {
      dispatch(setOffDaysFailure(err));
      dispatch(dataFailure())
    }
  }
}

const requestOffDaysData = (approvementId) => {
  return {
    type: REQUEST_OFFDAYS,
    payload: approvementId
  }
}

export const requestOffDays = (approvementId) => {
  return (dispatch) => {
    try {
      dispatch(requestUserOffDays(approvementId));
      dispatch(requestOffDaysData(approvementId))
    } catch(err) {
      dispatch(setOffDaysFailure(err));
    }
  }
}

const publishOffDaysData = (approvementId) => {
  return {
    type: PUBLISH_OFFDAYS,
    payload: approvementId
  }
}

export const publishOffDays = (approvementId) => {
  return (dispatch) => {
    try {
      dispatch(publishUserOffDays(approvementId));
      dispatch(publishOffDaysData(approvementId))
    } catch(err) {
      dispatch(setOffDaysFailure(err));
    }
  }
}

const acceptOffDaysData = (approvementId) => {
  return {
    type: ACCEPT_OFFDAYS,
    payload: approvementId
  }
}

export const acceptOffDays = (approvementId) => {
  return (dispatch) => {
    try {
      dispatch(acceptUserOffDays(approvementId));
      dispatch(acceptOffDaysData(approvementId))
    } catch(err) {
      dispatch(setOffDaysFailure(err));
    }
  }
}

const declineOffDaysData = (approvementId) => {
  return {
    type: DECLINE_OFFDAYS,
    payload: approvementId
  }
}

export const declineOffDays = (approvementId) => {
  return (dispatch) => {
    try {
      dispatch(declineUserOffDays(approvementId));
      dispatch(declineOffDaysData(approvementId))
    } catch(err) {
      dispatch(setOffDaysFailure(err));
    }
  }
}

const setApprovementStateData = (values) => {
  return {
    type: SET_APPROVEMENT_STATE,
    payload: values
  }
}

export const resetApprovementState = (values) => {
  return (dispatch) => {
    try {
      dispatch(setApprovementState(values))
      dispatch(setApprovementStateData(values))
    } catch(err) {
      dispatch(setOffDaysFailure(err));
    }
  }
}