import {
  FETCH_LOG_SUCCESS,
} from "actions/types";

import { addLog, readLog } from "../api";

import { dataFailure } from "./general";

const LogFailure = (err) => {
  return {
    type: "LOG_FAILURE",
    payload: err,
  };
};

export const newLogEntry = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(addLog(values));
    } catch (err) {
      await dispatch(LogFailure(err));
    }
  };
};

const fetchLogSuccess = (values) => {
  return {
    type: FETCH_LOG_SUCCESS,
    payload: values,
  };
};

export const fetchLog = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(readLog(values));
      await dispatch(fetchLogSuccess(JSONData));
    } catch (err) {
      await dispatch(dataFailure("Log: fetch " + err.message));
    }
  };
};
