import {
  FETCH_DASHBOARD_SUCCESS,
} from 'actions/types'

import { dashboard } from '../api'
import {
  dataFailure,
} from "./general";

const fetchDashboardSuccess = (DashboardData) => {
  return {
    type: FETCH_DASHBOARD_SUCCESS,
    payload: DashboardData
  }
}
 
export const fetchDashboard = (id) => {
  return async (dispatch) => {
    try {
      const DashboardJSONData = await dispatch(dashboard(id))
      await dispatch(fetchDashboardSuccess(DashboardJSONData))
    } catch(err) {
      await dispatch(dataFailure(err.message))
    }
  }
}