import {
  AUTH_AUTHENTICATING,
  AUTH_AUTHENTICATED,
  AUTH_ERROR,
} from "../actions/types";
import { login, renewToken } from "../api";

export function authLogin(user, pass) {
  return async (dispatch) => {
    try {
      // Get and set Token
      const responseJSON = await dispatch(login(user, pass));
      if (responseJSON.jwt) {
        window.localStorage.setItem("jwt", responseJSON.jwt);
        document.cookie = "jwt=" + responseJSON.jwt;
        // Login
        await dispatch(authAuthenticated(true));
      } else {
        window.localStorage.removeItem("jwt");
        document.cookie = "jwt=";
        await dispatch(authError());
      }
    } catch (err) {
      window.localStorage.removeItem("jwt");
      document.cookie = "jwt=";
      console.error(err);
      await dispatch(authError());
    }
  };
}

export function renewAuthToken(jwt) {
  return async (dispatch) => {
    try {
      // Get and set token
      document.cookie = `jwt=${jwt}`;
      const responseJSON = await dispatch(renewToken());
      if (responseJSON.jwt) {
        window.localStorage.setItem("jwt", responseJSON.jwt);
        document.cookie = "jwt=" + responseJSON.jwt;

        // Get settings
        // const settingsResult = await dispatch(settings());
        // const lang = settingsResult.settings.lang;
        // if (lang) {
        //   i18n.changeLanguage(lang);
        //   window.localStorage.setItem("lang", lang);
        // }
        // await dispatch({ type: SETTINGS_RECEIVED, settings: settingsResult });
        // await dispatch({
        //   type: PROFILE_USER_RECEIVED,
        //   person: settingsResult.person,
        // });

        // Login
        await dispatch(authAuthenticated(true));
      } else {
        window.localStorage.removeItem("jwt");
        document.cookie = "jwt=";
        await dispatch(authError());
      }
    } catch (err) {
      window.localStorage.removeItem("jwt");
      document.cookie = "jwt=";
      console.error(err);
      await dispatch(authError());
    }
  };
}

export function authLoginWithToken(jwt) {
  return async (dispatch) => {
    document.cookie = `jwt=${jwt}`;
    await dispatch(authAuthenticated(true));
  };
}

export function authLogout() {
  return async (dispatch) => {
    document.cookie = "jwt=";
    window.localStorage.removeItem("jwt");
    await dispatch(authAuthenticated(false));
  };
}

function authError() {
  return {
    type: AUTH_ERROR,
  };
}

export function authAuthenticating(authenticating = true) {
  return {
    type: AUTH_AUTHENTICATING,
    authenticating,
  };
}

export function authAuthenticated(isAuthenticated = true) {
  return {
    type: AUTH_AUTHENTICATED,
    isAuthenticated,
  };
}
