import {
  SET_SELECTED_RECORD_ID,
  SET_SELECTED_SUBRECORD_ID
} from "actions/types";

export const setSelectedRecordId = (value) => {
  return{
    type: SET_SELECTED_RECORD_ID,
    payload: value
  }
}

export const setSelectedSubrecordId = (value) => {
  return{
    type: SET_SELECTED_SUBRECORD_ID,
    payload: value
  }
}
