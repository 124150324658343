import {
  FETCH_UNPUBLISHED_DAYS_SUCCESS,
} from  'actions/types'

import { 
  unpublishedDays
} from '../api'

import { dataFailure } from "./general";

const fetchUnpublishedDaysSuccess = (unpublishedDays) => {
  return {
    type: FETCH_UNPUBLISHED_DAYS_SUCCESS,
    payload: unpublishedDays
  }
}

export const fetchUnpublishedDays = (userId) => {
  return async (dispatch) => {
    try {
      const unpublishedDaysJSONData = await dispatch(unpublishedDays(userId))
      await dispatch(fetchUnpublishedDaysSuccess(unpublishedDaysJSONData))
    } catch(err) {
      await dispatch(dataFailure(err.message))
    }
  }
}