import {
  FETCH_YEAR_SUCCESS,
  FETCH_YEAR_STATIC_SUCCESS,
  FETCH_YEAR_COUNT_SUCCESS,
  FETCH_YEAR_USER_SUCCESS,
  FETCH_YEAR_TYPES_SUCCESS,
  SET_YEAR_WORKDAYS,
} from "actions/types";

import produce from 'immer'

const initialState = {
  Year: [],
  YearStatic: [],
  YearCount: [],
  YearUser: [],
  YearUserAbsenceTypes: [],
  YearWorkdays: 0,
}

const yearReducer = (state = initialState, action) =>
  produce(state, draft => {
    const PL = action.payload
    switch (action.type) {
      case FETCH_YEAR_SUCCESS:
        draft.Year = PL;
        break;
      case FETCH_YEAR_STATIC_SUCCESS:
        draft.YearStatic = PL;
        break;
      case FETCH_YEAR_COUNT_SUCCESS:
        draft.YearCount = PL;
        break;
      case FETCH_YEAR_USER_SUCCESS:
        draft.YearUser = PL;
        break;
      case FETCH_YEAR_TYPES_SUCCESS:
        draft.YearUserAbsenceTypes = PL;
        break;
      case SET_YEAR_WORKDAYS:
        draft.YearWorkdays = PL
        break
      default:
        return state;
    }
  })

export default yearReducer