import {
  FETCH_TIMECLOCKS_SUCCESS,
  FETCH_TIMECLOCKS_FAILURE,
  SET_SELECTED_TIMECLOCK,
  CREATE_TIMECLOCK,
  UPDATE_TIMECLOCK,
  DELETE_TIMECLOCK,
} from "../actions/types";
import produce from "immer";

const initialState = {
  TimeClocks: [],
  selectedTimeclock: -1,
  error: "",
};

const timeReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    var key;
    switch (action.type) {
      case FETCH_TIMECLOCKS_SUCCESS:
        draft.TimeClocks = action.payload;
        draft.error = "";
        break;
      case FETCH_TIMECLOCKS_FAILURE:
        draft.error = action.payload;
        break;
      case CREATE_TIMECLOCK:
        draft.TimeClocks = [...draft.TimeClocks, action.payload];
        break;
      case UPDATE_TIMECLOCK:
        key = draft.TimeClocks.findIndex((TC) => TC.id === action.payload.id);
        draft.TimeClocks[key] = action.payload;
        draft.selectedTimeclock = -1;
        break;
      case DELETE_TIMECLOCK:
        key = draft.TimeClocks.findIndex((TC) => TC.id === action.payload.id);
        draft = { TimeClocks: draft.TimeClocks.splice(key, 1) };
        draft.selectedTimeclock = -1;
        break;
      case SET_SELECTED_TIMECLOCK:
        draft.selectedTimeclock = action.payload;
        break;
      default:
        return draft;
    }
  });

export default timeReducer;
