import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import history from './history'
import { DndProvider } from "react-dnd";
import { IntlProvider } from "react-intl";
import { HTML5Backend } from "react-dnd-html5-backend";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import "./index.css";
import App from "App";
import { store } from "./redux/store";
import theme from "theme/Theme";
import "./i18n";
// import "@fontsource/roboto/300.css";
// import "@fontsource/roboto/400.css";
// import "@fontsource/roboto/500.css";
// import "@fontsource/roboto/700.css";

function main() {
  const rootElement = document.getElementById("root");

  createRoot(rootElement).render(
    <IntlProvider
      messages={{}}
      locale={window.navigator.language}
    >
      <StyledEngineProvider injectFirst>
        <DndProvider backend={HTML5Backend}>
          <Provider store={store}>
            <ThemeProvider theme={theme}>
              <Router history={history}>
                <App />
              </Router>
            </ThemeProvider>
          </Provider>
        </DndProvider>
      </StyledEngineProvider>
    </IntlProvider>
  );
}

main();
