import {
  FETCH_TODAY_STATE,
} from "./types";

import { todayState } from "../api";

import {
  dataFailure,
} from "./general";

const fetchTodaySuccess = (data) => {
  return {
    payload: data,
    type: FETCH_TODAY_STATE,
  };
};

export const fetchToday = (values) => {
  return async (dispatch) => {
    try {
      const todayStatic = await dispatch(todayState(values));
      await dispatch(fetchTodaySuccess(todayStatic));
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

