import {
  FETCH_REMINDER_CATEGORIES_SUCCESS,
  ADD_REMINDER_CATEGORY,
  UPDATE_REMINDER_CATEGORY,
  DELETE_REMINDER_CATEGORY,
} from "actions/types";
import produce from "immer";

const initialState = {
  ReminderCategories: [],
  error: "",
};

const remindertimesReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    let key = 0;
    switch (action.type) {
      case FETCH_REMINDER_CATEGORIES_SUCCESS:
        draft.ReminderCategories = action.payload;
        draft.error = "";
        break;
      case ADD_REMINDER_CATEGORY:
        draft.ReminderCategories = [...draft.ReminderCategories, action.payload];
        break;
      case UPDATE_REMINDER_CATEGORY:
        key = draft.ReminderCategories.findIndex((RC) => RC.id === action.payload.id);
        draft.ReminderCategories[key] = action.payload;
        break;
      case DELETE_REMINDER_CATEGORY:
        key = draft.ReminderCategories.findIndex(
          (RC) => RC.id === action.payload.id
        );
        draft = { ReminderCategories: draft.ReminderCategories.splice(key, 1) };
        break;
      default:
        return state;
    }
  });

export default remindertimesReducer;
