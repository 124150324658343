import IconButton from "@mui/material/IconButton";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const NextButton = (props) => {
  return (
    <IconButton onClick={props.onClick} sx={props.sx}>
      <ChevronRightIcon />
    </IconButton>
  );
};

export default NextButton;
