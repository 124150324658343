import {
  FETCH_CHANGELOG_SUCCESS,
  CREATE_CHANGELOG_SUCCESS,
  UPDATE_CHANGELOG_SUCCESS,
  DELETE_CHANGELOG_SUCCESS,
  SET_CHANGELOG_ID
} from "actions/types";

import {
  changeLogRead, changeLogCreate, changeLogUpdate, changeLogDelete} from "../api";
import {
  dataFailure,
  dataSuccess,
} from "./general";


const fetchChangeLogSuccess = (values) => {
  return {
    type: FETCH_CHANGELOG_SUCCESS,
    payload: values,
  };
};

export const fetchChangeLog = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(changeLogRead(values));
      await dispatch(fetchChangeLogSuccess(JSONData));
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

const createChangeLogData = (values) => {
  return {
    type: CREATE_CHANGELOG_SUCCESS,
    payload: values,
  };
};

export const createChangeLog = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(changeLogCreate(values));
      await dispatch(createChangeLogData(JSONData));
      await dispatch(dataSuccess());
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

const updateChangeLogData = (values) => {
  return {
    type: UPDATE_CHANGELOG_SUCCESS,
    payload: values,
  };
};

export const updateChangeLog = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(changeLogUpdate(values));
      await dispatch(updateChangeLogData(JSONData));
      await dispatch(dataSuccess());
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

const deleteChangeLogData = (values) => {
  return {
    type: DELETE_CHANGELOG_SUCCESS,
    payload: values,
  };
};

export const deleteChangeLog = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(changeLogDelete(values));
      await dispatch(deleteChangeLogData(values));
      await dispatch(dataSuccess());
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

export const setChangeLogId = (values) => {
  return {
    type: SET_CHANGELOG_ID,
    payload: values,
  };
};