import {
  FETCH_PROJECTS_SUCCESS,
  CREATE_PROJECT,
  UPDATE_PROJECT,
  DELETE_PROJECT,
  SET_PROJECT_ID,
} from "actions/types";

import {
  projectsRead,
  projectCreate,
  projectUpdate,
  projectDelete} from "../api";
import {
  dataFailure,
  dataSuccess,
} from "./general";

const fetchProjectsSuccess = (values) => {
  return {
    type: FETCH_PROJECTS_SUCCESS,
    payload: values,
  };
};

export const fetchProjects = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(projectsRead(values));
      await dispatch(fetchProjectsSuccess(JSONData));
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

const createProjectData = (values) => {
  return {
    type: CREATE_PROJECT,
    payload: values,
  };
};

export const createProject = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(projectCreate(values));
      await dispatch(createProjectData({ ...values, id: JSONData }));
      await dispatch(dataSuccess());
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

const updateProjectData = (values) => {
  return {
    type: UPDATE_PROJECT,
    payload: values,
  };
};

export const updateProject = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(projectUpdate(values));
      await dispatch(updateProjectData(values));
      await dispatch(dataSuccess());
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};
const deleteProjectData = (values) => {
  return {
    type: DELETE_PROJECT,
    payload: values,
  };
};

export const deleteProject = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(projectDelete(values));
      await dispatch(deleteProjectData({ ...values, id: JSONData }));
      await dispatch(dataSuccess());
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};


export const setProjectId = (values) => {
  return {
    type: SET_PROJECT_ID,
    payload: values,
  };
};
