import {
  FETCH_GLOBAL_SETTINGS_SUCCESS,
  SET_GLOBAL_SETTING,
  FETCH_PERSONAL_SETTINGS_SUCCESS,
  SET_PERSONAL_SETTING,
  CREATE_PERSONAL_SETTINGS,
  FETCH_PERSONAL_DISPLAYSETTINGS_SUCCESS,
  SET_PERSONAL_DISPLAYSETTINGS,
  CREATE_PERSONAL_DISPLAYSETTINGS,
  FETCH_STANDARD_DISPLAYSETTINGS_SUCCESS,
} from "actions/types";

import {
  globalSettingsRead,
  globalSettingsSet,
  personalSettingsSet,
  personalSettingsRead,
  personalSettingsCreate,
  personalDisplaySettingsSet,
  personalDisplaySettingsRead,
  personalDisplaySettingsCreate,
  standardDisplaySettingsRead,
} from "../api";
import {
  dataFailure,
  dataSuccess,
} from "./general";

const fetchGlobalSettingsSuccess = (settings) => {
  return {
    type: FETCH_GLOBAL_SETTINGS_SUCCESS,
    payload: settings
  }
}

export const fetchGlobalSettings = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(globalSettingsRead(values))
      await dispatch(fetchGlobalSettingsSuccess(JSONData))
    } catch(err) {
      await dispatch(dataFailure(err.message))
    }
  }
}

const setGlobalSettingsData = (values) => {
  return {
    type: SET_GLOBAL_SETTING,
    payload: values
  }
}

export const setGlobalSettings = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(globalSettingsSet(values));
      await dispatch(setGlobalSettingsData(values));
      await dispatch(dataSuccess());
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  }
}

const fetchPersonalSettingsSuccess = (settings) => {
  return {
    type: FETCH_PERSONAL_SETTINGS_SUCCESS,
    payload: settings
  }
}

export const fetchPersonalSettings = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(personalSettingsRead(values))
      await dispatch(fetchPersonalSettingsSuccess(JSONData))
    } catch(err) {
      await dispatch(dataFailure(err.message))
    }
  }
}

const setPersonalSettingsData = (values) => {
  return {
    type: SET_PERSONAL_SETTING,
    payload: values
  }
}

export const setPersonalSettings = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(personalSettingsSet(values));
      await dispatch(setPersonalSettingsData(values));
      if(!values.noSuccessMessage){
        await dispatch(dataSuccess());
      }
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  }  
}

const createPersonalSettingsData = (values) => {
  return {
    type: CREATE_PERSONAL_SETTINGS,
    payload: values,
  };
};

export const createPersonalSettings = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(personalSettingsCreate(values));
      await dispatch(createPersonalSettingsData(values));
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  }  
}

const fetchPersonalDisplaySettingsSuccess = (settings) => {
  return {
    type: FETCH_PERSONAL_DISPLAYSETTINGS_SUCCESS,
    payload: settings,
  };
};

export const fetchPersonalDisplaySettings = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(personalDisplaySettingsRead(values));
      await dispatch(fetchPersonalDisplaySettingsSuccess(JSONData));
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

const setPersonalDisplaySettingsData = (values) => {
  return {
    type: SET_PERSONAL_DISPLAYSETTINGS,
    payload: values,
  };
};

export const setPersonalDisplaySettings = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(personalDisplaySettingsSet(values));
      await dispatch(setPersonalDisplaySettingsData(values));
      await dispatch(dataSuccess());
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

const createPersonalDisplaySettingsData = (values) => {
  return {
    type: CREATE_PERSONAL_DISPLAYSETTINGS,
    payload: values,
  };
};

export const createPersonalDisplaySettings = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(personalDisplaySettingsCreate(values));
      await dispatch(createPersonalDisplaySettingsData(values));
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

const fetchStandardDisplaySettingsSuccess = (settings) => {
  return {
    type: FETCH_STANDARD_DISPLAYSETTINGS_SUCCESS,
    payload: settings,
  };
};

export const fetchStandardDisplaySettings = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(standardDisplaySettingsRead(values));
      await dispatch(fetchStandardDisplaySettingsSuccess(JSONData));
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};
