import {
  FETCH_COUNTRIES_SUCCESS,
  UPDATE_COUNTRY,
  UPDATE_REGION,
  DELETE_REGION,
} from "actions/types";
import produce from "immer";

const initialState = {
  Countries: [],
};

const countriesReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    var key;
    var regionKey;
    switch (action.type) {
      case FETCH_COUNTRIES_SUCCESS:
        draft.Countries = action.payload;

        draft.error = "";
        break;
      case UPDATE_COUNTRY:
        key = draft.Countries.findIndex((C) => C.id === action.payload.id);
        if (key === -1) {
          draft.Countries = [...draft.Countries, action.payload];
        } else {
          draft.Countries[key] = action.payload;
        }
        break;
      case UPDATE_REGION:
        key = draft.Countries.findIndex(
          (C) => C.id === action.payload.countryId
        );
        if (action.payload.id === 0) {
          // Add new region
          draft.Countries[key].regions = [
            ...draft.Countries[key].regions,
            action.payload,
          ];
        } else {
          // Update existing region
          regionKey = draft.Countries[key].regions.findIndex(
            (R) => R.id === action.payload.id
          );
          draft.Countries[key].regions[regionKey] = action.payload;
        }
        break;
      case DELETE_REGION:
        key = draft.Countries.findIndex(
          (C) => C.id === action.payload.countryId
        );
        regionKey = draft.Countries[key].regions.findIndex(
          (R) => R.id === action.payload.id
        );
        const regionArr = draft.Countries[key].regions;
        draft.Countries[key] = {
          ...draft.Countries[key],
          regions: regionArr.splice(regionKey, 1),
        };
        break;
      default:
        return draft;
    }
  });

export default countriesReducer;
