import {
  FETCH_REMINDER_CATEGORIES_SUCCESS,
  ADD_REMINDER_CATEGORY,
  UPDATE_REMINDER_CATEGORY,
  DELETE_REMINDER_CATEGORY,
  SET_SELECTED_REMINDER_CATEGORY,
} from "actions/types";
import { reminderCategories, reminderCategoryAdd, reminderCategoryUpdate, reminderCategoryDelete } from "../api";
import { dataSuccess, dataFailure } from "./general";

const fetchReminderCategoriesSuccess = (values) => {
  return {
    type: FETCH_REMINDER_CATEGORIES_SUCCESS,
    payload: values
  }
}

export const fetchReminderCategories = (values) => {
  return async (dispatch) => {
    try {
      const returnJSONData = await dispatch(reminderCategories(values))
      await dispatch(fetchReminderCategoriesSuccess(returnJSONData))
    } catch(err) {
      await dispatch(dataFailure(err.message))
    }
  }
}

const addReminderCategoryData = (values) => {
  return {
    type: ADD_REMINDER_CATEGORY,
    payload: values,
  };
};

export const addReminderCategory = (values) => {
  return async (dispatch) => {
    try {
      const returnJSONData = await dispatch(reminderCategoryAdd(values));
      await dispatch(addReminderCategoryData({...values, id: returnJSONData}));
      await dispatch(dataSuccess());
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

const updateReminderCategoryData = (values) => {
  return {
    type: UPDATE_REMINDER_CATEGORY,
    payload: values,
  };
};

export const updateReminderCategory = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(reminderCategoryUpdate(values));
      await dispatch(updateReminderCategoryData(values));
      await dispatch(dataSuccess());
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};



const deleteReminderCategoryData = (values) => {
  return {
    type: DELETE_REMINDER_CATEGORY,
    payload: values,
  };
};

export const deleteReminderCategory = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(reminderCategoryDelete(values));
      await dispatch(deleteReminderCategoryData(values));
      await dispatch(dataSuccess());
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

export const setSelectedReminderCategory = (values) => {
  return {
    type: SET_SELECTED_REMINDER_CATEGORY,
    payload: values,
  };
};
