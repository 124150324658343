import {
  FETCH_DAILY_TIME_PLANS_SUCCESS,
  CREATE_DAILY_TIME_PLAN,
  UPDATE_DAILY_TIME_PLAN,
  DELETE_DAILY_TIME_PLAN,
  SET_DAILY_TIME_PLAN_ID,
} from "actions/types";

import {
  dailyTimePlansRead,
  dailyTimePlanCreate,
  dailyTimePlanUpdate,
  dailyTimePlanDelete} from "../api";
import {
  dataFailure,
  dataSuccess,
} from "./general";

const fetchDailyTimePlansSuccess = (values) => {
  return {
    type: FETCH_DAILY_TIME_PLANS_SUCCESS,
    payload: values,
  };
};

export const fetchDailyTimePlans = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(dailyTimePlansRead(values));
      await dispatch(fetchDailyTimePlansSuccess(JSONData));
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

const createDailyTimePlanData = (values) => {
  return {
    type: CREATE_DAILY_TIME_PLAN,
    payload: values,
  };
};

export const createDailyTimePlan = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(dailyTimePlanCreate(values));
      await dispatch(createDailyTimePlanData({ ...values, id: JSONData }));
      await dispatch(dataSuccess());
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

const updateDailyTimePlanData = (values) => {
  return {
    type: UPDATE_DAILY_TIME_PLAN,
    payload: values,
  };
};

export const updateDailyTimePlan = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(dailyTimePlanUpdate(values));
      await dispatch(updateDailyTimePlanData(values));
      await dispatch(dataSuccess());
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};
const deleteDailyTimePlanData = (values) => {
  return {
    type: DELETE_DAILY_TIME_PLAN,
    payload: values,
  };
};

export const deleteDailyTimePlan = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(dailyTimePlanDelete(values));
      await dispatch(deleteDailyTimePlanData({ ...values, id: JSONData }));
      await dispatch(dataSuccess());
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};


export const setDailyTimePlanId = (values) => {
  return {
    type: SET_DAILY_TIME_PLAN_ID,
    payload: values,
  };
};
