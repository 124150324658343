import {
  FETCH_ROLLS_SUCCESS,
  CREATE_ROLL_SUCCESS,
  UPDATE_ROLL_SUCCESS,
  DELETE_ROLL_SUCCESS,
  COPY_RIGHTS_SUCCESS,
  SET_SELECTED_ROLL_ID,
} from "actions/types";

import {
  rollsRead, rollCreate, rollUpdate, rollDelete, rightsCopy} from "../api";
import {
  dataFailure,
  dataSuccess,
} from "./general";


const fetchRollsSuccess = (values) => {
  return {
    type: FETCH_ROLLS_SUCCESS,
    payload: values,
  };
};

export const fetchRolls = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(rollsRead(values));
      await dispatch(fetchRollsSuccess(JSONData));
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

const createRollData = (values) => {
  return {
    type: CREATE_ROLL_SUCCESS,
    payload: values,
  };
};

export const createRoll = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(rollCreate(values));
      await dispatch(createRollData({ ...values, id: JSONData }));
      await dispatch(dataSuccess());
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

const updateRollData = (values) => {
  return {
    type: UPDATE_ROLL_SUCCESS,
    payload: values,
  };
};

export const updateRoll = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(rollUpdate(values));
      await dispatch(updateRollData(values));
      await dispatch(dataSuccess());
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

const deleteRollData = (values) => {
  return {
    type: DELETE_ROLL_SUCCESS,
    payload: values,
  };
};

export const deleteRoll = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(rollDelete(values));
      await dispatch(deleteRollData(values));
      await dispatch(dataSuccess());
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

const copyRightsData = (values) => {
  return {
    type: COPY_RIGHTS_SUCCESS,
    payload: values,
  };
};

export const copyRights = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(rightsCopy(values));
      await dispatch(copyRightsData(values));
      await dispatch(dataSuccess());
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

export const setSelectedRollId = (values) => {
  return {
    type: SET_SELECTED_ROLL_ID,
    payload: values
  };
}

// export const setWeeklyTimePlanId = (values) => {
//   return {
//     type: SET_WEEKLY_TIME_PLAN_ID,
//     payload: values,
//   };
// };
