import {
  SET_SELECTED_RECORD_ID,
  SET_SELECTED_SUBRECORD_ID,
  SET_ENTITLEMENT_ID,
  SET_TIMEPLAN_ID,
  SET_SALARY_ID,
  SET_COVSTATE_ID,
  SET_DISPLAY_USER,
  SET_DISPLAY_DEPARTMENT,
  SET_DISPLAY_APPROVEMENT,
  SET_SELECTED_DATES,
  SELECT_SELECTED_DATE,
  SELECT_SELECTED_DATES,
  SET_SELECTED_REMINDER,
  SET_SELECTED_REMINDER_CATEGORY,
  SET_SELECTED_LOCATION_ID,
  ADD_REMINDER_CATEGORY,
  UPDATE_REMINDER_CATEGORY,
  DELETE_REMINDER_CATEGORY,
  SET_DISPLAY_DATE
} from "actions/types";

import produce from "immer";

const initialState = {
  selectedRecordId: -1,
  selectedSubrecordId: -1,
  selectedEntitlementId: -1,
  selectedTimePlanId: -1,
  selectedSalaryId: -1,
  selectedCovStateId: -1,
  selectedUserId: -1,
  selectedDepartmentId: -1,
  selectedApprovementId: 0,
  selectedReminderId: -1,
  selectedReminderCategoryId: -1,
  selectedLocationId: 0,
  selectedDates: [],
  DisplayDate: ""
};

const selectionsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const PL = action.payload;
    var key;
    // eslint-disable-next-line
    switch (action.type) {
      case SET_SELECTED_RECORD_ID:
        draft.selectedRecordId = PL;
        break;
      case SET_SELECTED_SUBRECORD_ID:
        draft.selectedSubrecordId = PL;
        break;
      case SET_ENTITLEMENT_ID:
        draft.selectedEntitlementId = PL;
        break;
      case SET_TIMEPLAN_ID:
        draft.selectedTimePlanId = PL;
        break;
      case SET_SALARY_ID:
        draft.selectedSalaryId = PL;
        break;
      case SET_COVSTATE_ID:
        draft.selectedCovStateId = PL;
        break;
      case SET_DISPLAY_USER:
        draft.selectedUserId = PL;
        break;
      case SET_DISPLAY_DEPARTMENT:
        draft.selectedDepartmentId = PL;
        break;
      case SET_DISPLAY_APPROVEMENT:
        draft.selectedApprovementId = PL;
        break;
      case SET_SELECTED_DATES:
        draft.selectedDates = PL;
        break;
      case SELECT_SELECTED_DATE:
        key = draft.selectedDates.findIndex((SD) => SD.date === PL.date);
        draft.selectedDates[key].selected = !draft.selectedDates[key].selected;
        break;
      case SELECT_SELECTED_DATES:
        draft.selectedDates
          // eslint-disable-next-line array-callback-return
          .map((SD) => {
            SD.selected = PL;
          });
        break;
      case SET_SELECTED_REMINDER:
        draft.selectedReminderId = PL;
        break;
      case SET_SELECTED_REMINDER_CATEGORY:
        draft.selectedReminderCategoryId = PL;
        break;
      case SET_SELECTED_LOCATION_ID:
        draft.selectedLocationId = PL;
        break;
      case (ADD_REMINDER_CATEGORY,
      UPDATE_REMINDER_CATEGORY,
      DELETE_REMINDER_CATEGORY):
        draft.selectedReminderCategoryId = -1;
        break;
      case SET_DISPLAY_DATE:
        draft.DisplayDate = PL;
        break
      default:
        return state;
    }
  });

export default selectionsReducer;
