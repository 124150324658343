import {
  READ_EXIT_REASONS,
  CREATE_EXIT_REASON,
  UPDATE_EXIT_REASON,
  DELETE_EXIT_REASON,
  SET_SELECTED_EXIT_REASON,
} from "actions/types";

import {
  exitReasons,
  exitReasonUpdate,
  exitReasonCreate,
  exitReasonDelete,
} from "../api";
import { dataFailure, dataSuccess } from "./general";

const fetchExitReasonsSuccess = (offDayTypes) => {
  return {
    type: READ_EXIT_REASONS,
    payload: offDayTypes,
  };
};

export const fetchExitReasons = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(exitReasons(values));
      await dispatch(fetchExitReasonsSuccess(JSONData));
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

const updateExitReasonData = (values) => {
  return {
    type: UPDATE_EXIT_REASON,
    payload: values,
  };
};

export const updateExitReason = (values) => {
  return async (dispatch) => {
    try {
      const responseJSON = await dispatch(exitReasonUpdate(values));
      await dispatch(updateExitReasonData(responseJSON));
      await dispatch(dataSuccess());
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

const createExitReasonData = (values) => {
  return {
    type: CREATE_EXIT_REASON,
    payload: values,
  };
};

export const createExitReason = (values) => {
  return async (dispatch) => {
    try {
      const responseJSON = await dispatch(exitReasonCreate(values));
      await dispatch(createExitReasonData(responseJSON));
      await dispatch(dataSuccess());
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

const deleteExitReasonData = (id) => {
  return {
    type: DELETE_EXIT_REASON,
    payload: id,
  };
};

export const deleteExitReason = (id) => {
  return async (dispatch) => {
    try {
      await dispatch(exitReasonDelete(id));
      await dispatch(deleteExitReasonData(id));
      await dispatch(dataSuccess());
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

export const setExitReasonId = (id) => {
  return {
    type: SET_SELECTED_EXIT_REASON,
    payload: id
  };
}
