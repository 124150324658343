import {
  INIT_DATABASE,
  SET_EDIT_MODE,
  SET_DRAWER_OPEN,
  SET_DISPLAY_YEAR,
  SET_DISPLAY_ABSENCE_TYPE,
  DATA_FAILURE,
  DATA_SUCCESS,
  DATA_RESET,
  ACTION_SUCCESS,
  FETCH_CURRENT_STATS,
  FETCH_CURRENT_VERSIONS,
  FETCH_SOMURA_START
} from "actions/types";

import {
  initDatabase,
  getCurrentStats,
  getCurrentVersions,
  getSomuraStart,
} from "../api";

export const dataFailure = (err) => {
  return {
    type: DATA_FAILURE,
    payload: err
  };
};

export const dataSuccess = () => {
  return {
    type: DATA_SUCCESS,
  };
};

export const dataReset = () => {
  return {
    type: DATA_RESET,
  };
};

const initialzeDatabaseSuccess = () => {
  return {
    type: INIT_DATABASE,
  };
};

export const initializeDatabase = () => {
  return (dispatch) => {
    try {
      dispatch(initDatabase());
      dispatch(initialzeDatabaseSuccess());
    } catch (err) {
      dispatch(dataFailure());
    }
  };
};

export const setEditMode = (editMode) => {
  return {
    type: SET_EDIT_MODE,
    payload: editMode,
  };
};

export const setDrawerOpen = (open) => {
  return {
    type: SET_DRAWER_OPEN,
    payload: open,
  };
};

export const setDisplayYear = (year) => {
  return {
    type: SET_DISPLAY_YEAR,
    payload: year,
  };
};

export const setDisplayAbsenceType = (month) => {
  return {
    type: SET_DISPLAY_ABSENCE_TYPE,
    payload: month,
  };
};

export const actionSuccess = () => {
  return {
    type: ACTION_SUCCESS,
  };
};

const fetchCurrentStatsSuccess = (values) => {
  return {
    type: FETCH_CURRENT_STATS,
    payload: values
  };
}

export const fetchCurrentStats = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(getCurrentStats(values));
      await dispatch(fetchCurrentStatsSuccess(JSONData));
    } catch (err) {
      console.log("Error: ", err);
    }
  };
};

const fetchCurrentVersionsSuccess = (values) => {
  return {
    type: FETCH_CURRENT_VERSIONS,
    payload: values
  };
}

export const fetchCurrentVersions = () => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(getCurrentVersions());
      await dispatch(fetchCurrentVersionsSuccess(JSONData));
    } catch (err) {
      ;
    }
  };
};

const fetchSomuraStartSuccess = (values) => {
  return {
    type: FETCH_SOMURA_START,
    payload: values
  };
}

export const fetchSomuraStart = () => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(getSomuraStart());
      await dispatch(fetchSomuraStartSuccess(JSONData));
    } catch (err) {
      ;
    }
  };
};