import React from "react";
import { connect } from "react-redux";
import LinearProgress from "@mui/material/LinearProgress";

const mapStateToProps = (state) => {
  return {
    progressValue: state.general.progressValue,
  };
};

const SomuraProgress = ({ progressValue }) => {
  return (
    <LinearProgress
      variant="determinate"
      value={progressValue}
      sx={{
        backgroundColor: "red",
        "& .MuiLinearProgress-bar": {
          backgroundColor: "green",
        },
      }}
    />
  );
};

export default connect(mapStateToProps, null)(SomuraProgress);
