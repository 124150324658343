import React from "react";
import { useTranslation } from "react-i18next";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import theme from "../theme/Theme";

const SomuraTextField = (props) => {
  const { t } = useTranslation();
  const input = props.value === 0 || props.value ? props.value : "";
  return (
    <FormControl sx={{ width: props.width || "100%" }}>
      <TextField
        sx={{
          marginBottom: "2px",
          color:"text.main",
          ...(props.required
            ? {
                borderRadius: "4px",
                boxShadow: `inset 0 0 5px ${theme.palette.secondary.main}`,
              }
            : null),
          fontSize: props.fontSize,
          ...props.sx
        }}
        required={props.required}
        disabled={props.disabled}
        type={props.type}
        name={props.name}
        value={input}
        label={t(props.label)}
        placeholder={props.placeholder}
        autoComplete={props.autocomplete}
        onChange={props.onChange}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        variant="outlined"
        size="small"
        autoFocus={props.autoFocus}
        multiline={props.multiline}
        rows={props.rows}
        InputLabelProps={{
          shrink:
            props.type === "date" || props.value !== "" || props.autoFocus,
        }}
        InputProps={{
          endAdornment: props.adornmentText ? (
            <InputAdornment
              sx={{
                color: "text.main",
              }}
              position="end"
            >
              {props.adornmentText}
            </InputAdornment>
          ) : (
            ""
          ),
          inputProps: {
            maxLength: props.maxlength,
            min: props.min,
            max: props.max,
            step: props.step,
            autoComplete: props.autocomplete,
            style: {
              textAlign: props.textAlign || "left",
              fontSize: props.fontSize,
              color: props.color || "text.main",
            },
          },
        }}
      />
    </FormControl>
  );
};

export default SomuraTextField;
