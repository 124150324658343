import {
  FETCH_WAGES_SUCCESS,
  SET_SALARY_ID,
  ADD_SALARY,
  UPDATE_SALARY,
  DELETE_SALARY,
} from "actions/types";

import { wages, salaryAdd, salaryUpdate, salaryDelete } from "../api";
import {
  dataFailure,
  dataSuccess,
} from "./general";

const fetchWagesSuccess = (values) => {
  return {
    type: FETCH_WAGES_SUCCESS,
    payload: values,
  };
};

export const fetchWages = (values) => {
  return async (dispatch) => {
    try {
      const receivedData = await dispatch(wages(values));
      await dispatch(fetchWagesSuccess(receivedData));
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

export const setSalaryId = (id) => {
  return {
    type: SET_SALARY_ID,
    payload: id,
  };
};

const addSalaryData = (values) => {
  return {
    type: ADD_SALARY,
    payload: values,
  };
};

export const addSalary = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(salaryAdd(values));
      await dispatch(addSalaryData(values));
      await dispatch(dataSuccess());
    } catch (err) {
      await dispatch(dataFailure(err.message));
      ;
    }
  };
};

const updateSalaryData = (values) => {
  return {
    type: UPDATE_SALARY,
    payload: values,
  };
};

export const updateSalary = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(salaryUpdate(values));
      await dispatch(updateSalaryData(values));
      await dispatch(dataSuccess());
    } catch (err) {
      await dispatch(dataFailure(err.message));
      await dispatch(dataFailure(err.message));
    }
  };
};

const deleteSalaryData = (values) => {
  return {
    type: DELETE_SALARY,
    payload: values,
  };
};

export const deleteSalary = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(salaryDelete(values));
      await dispatch(deleteSalaryData(values));
      await dispatch(dataSuccess());
    } catch (err) {
      await dispatch(dataFailure(err.message));
      await dispatch(dataFailure(err.message));
    }
  };
};