import React, { useState, Fragment } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import {languages} from "Constants"
import SomuraTooltip from "Components/SomuraTooltip";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";

const mapStateToProps = (state) => {
  const loggedUserId = state.loggeduser.LoggedUser.id;
  const loggedUser = state.persons.Persons.find((P) => P.id === loggedUserId);
  return {
    loginState: state.loggeduser.LoginState,
    loggedUser,
  };
};

const LanguageMenu = () => {
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const openUser = Boolean(anchorEl);

  const handleLanguageMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const language = languages.find(
    (L) => L.languageName === window.localStorage.getItem("lang")
  );

  const setNewLanguage = (language) => () => {
    i18n.changeLanguage(language);
    window.localStorage.setItem("lang", language);
    window.localStorage.setItem(
      "langShort",
      languages.find((L) => L.languageName === language).languageMenuName
    );
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Fragment>
      {language && (
        <>
          <SomuraTooltip
            title={t("Language") + ": " + language.languageLongName}
            placement="left"
          >
            <IconButton
              sx={{
                fontSize: "1rem",
              }}
              edge="end"
              aria-label="language"
              aria-controls="languagemenu"
              aria-haspopup="true"
              onClick={handleLanguageMenu}
              color="inherit"
              size="large"
            >
              {language.languageMenuName}
            </IconButton>
          </SomuraTooltip>
          <Menu
            id="languagemenu"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={openUser}
            onClose={handleClose}
          >
            {languages.map((L) => (
              <MenuItem
                key={L.languageName}
                disabled={L.languageName === language.languageName}
                onClick={setNewLanguage(L.languageName)}
              >
                {L.languageLongName}
              </MenuItem>
            ))}
          </Menu>
        </>
      )}
    </Fragment>
  );
};

export default connect(mapStateToProps, null)(LanguageMenu);
