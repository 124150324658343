import React from 'react'
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import InputAdornment from '@mui/material/InputAdornment'

const SomuraTextFieldLargeMUI = (props) => {
  const { t } = useTranslation();
  return (
    <TextField
      sx={{
        ...props.sx,
        width: "100%",
        border: "none",
        borderRadius: "4px",
        boxShadow: "inset 1px 1px 3px 1px rgba(0, 0, 0, 0.8)",
        marginBottom: "2px"
      }}
      disabled={props.disabled}
      type={props.type}
      name={props.name}
      value={props.value}
      label={t(props.label)}
      variant="outlined"
      size="small"
      autoFocus={props.autoFocus}
      multiline
      rows={props.rows}
      onChange={props.onChange}
      onBlur={props.onBlur}
      InputLabelProps={{
        shrink: props.type === "date" || props.value !== "" || props.autoFocus,
      }}
      InputProps={{
        endAdornment: props.adornmentText ? (
          <InputAdornment position="end">{props.adornmentText}</InputAdornment>
        ) : (
          ""
        ),
        inputProps: { min: props.min, max: props.max, step: props.step },
        style: { display: "block" },
      }}
    />
  );
}

export default SomuraTextFieldLargeMUI
