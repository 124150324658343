import {
  FETCH_REMARKS_SUCCESS,
  ADD_REMARK,
  UPDATE_REMARK,
  DELETE_REMARK,
  SET_REMARK_ID,
  SET_DISPLAY_REMARKS_IDS
} from "actions/types";
import { remarks, remarkAdd, remarkUpdate, remarkDelete } from "../api";
import {
  dataFailure,
  dataSuccess,
} from "./general";


const fetchRemarksSuccess = (values) => {
  return {
    type: FETCH_REMARKS_SUCCESS,
    payload: values,
  };
};

export const fetchRemarks = (values) => {
  return async (dispatch) => {
    try {
      const returnJSONData = await dispatch(remarks(values));
      await dispatch(fetchRemarksSuccess(returnJSONData));
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

const addRemarkData = (values) => {
  return {
    type: ADD_REMARK,
    payload: values,
  };
};

export const addRemark = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(remarkAdd(values));
      await dispatch(addRemarkData({ ...values, id: JSONData }));
      await dispatch(dataSuccess());
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

const updateRemarkData = (values) => {
  return {
    type: UPDATE_REMARK,
    payload: values,
  };
};

export const updateRemark = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(updateRemarkData(values));
      await dispatch(remarkUpdate(values));
      await dispatch(dataSuccess());
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

const deleteRemarkData = (values) => {
  return {
    type: DELETE_REMARK,
    payload: values,
  };
};

export const deleteRemark = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(deleteRemarkData(values));
      await dispatch(remarkDelete(values));
      await dispatch(dataSuccess());
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

export const setRemarkId = (values) => {
  return {
    type: SET_REMARK_ID,
    payload: values,
  };
};

export const setDisplayRemarksIds = (values) => {
  return{
    type: SET_DISPLAY_REMARKS_IDS,
    payload: values
  }
}
