import { FETCH_TODAY_STATE } from "actions/types";

import produce from 'immer'

const initialState = {
  TodayStatic: [],
}

const todayReducer = (state = initialState, action) =>
  produce(state, draft => {
    const PL = action.payload
    switch (action.type) {
      case FETCH_TODAY_STATE:
        draft.TodayStatic = PL;
        break;
      default:
        return state;
    }
  })

export default todayReducer