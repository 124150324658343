import {
  FETCH_GLOBAL_SETTINGS_SUCCESS,
  SET_GLOBAL_SETTING,
  FETCH_PERSONAL_SETTINGS_SUCCESS,
  SET_PERSONAL_SETTING,
  FETCH_PERSONAL_DISPLAYSETTINGS_SUCCESS,
  SET_PERSONAL_DISPLAYSETTINGS,
  FETCH_STANDARD_DISPLAYSETTINGS_SUCCESS,
  UPDATE_STANDARD_DISPLAYSETTINGS,
} from "actions/types";
import produce from "immer";

const initialState = {
  GlobalSettings: {},
  PersonalSettings: {},
  DisplaySettings: [],
  StandardSettings: [],
  error: "",
};

const settingsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    var key;
    switch (action.type) {
      case FETCH_GLOBAL_SETTINGS_SUCCESS:
        draft.GlobalSettings = action.payload.reduce((acc, curr) => {
          acc[curr.component] = {
            // Backwards compatability for now. Maybe remove this redundant depth in the future
            ...curr,
          };
          return acc;
        }, {});
        draft.error = "";
        break;
      case SET_GLOBAL_SETTING:
        draft.GlobalSettings[action.payload.component].settings[
          action.payload.key
        ] = action.payload.value;
        break;
      case FETCH_PERSONAL_SETTINGS_SUCCESS:
        draft.PersonalSettings = action.payload.reduce((acc, curr) => {
          acc[curr.component] = {
            // Backwards compatability for now. Maybe remove this redundant depth in the future
            ...curr,
          };
          return acc;
        }, {});
        draft.error = "";
        break;
      case SET_PERSONAL_SETTING:
        // TODO: Investiage the importance of `userId`
        draft.PersonalSettings[action.payload.component].settings[
          action.payload.key
        ] = action.payload.value;
        break;
      case FETCH_PERSONAL_DISPLAYSETTINGS_SUCCESS:
        draft.DisplaySettings = action.payload;
        draft.error = "";
        break;
      case FETCH_STANDARD_DISPLAYSETTINGS_SUCCESS:
        draft.StandardSettings = action.payload;
        draft.error = "";
        break;
      case UPDATE_STANDARD_DISPLAYSETTINGS:
        if (action.payload.standardDisplayId === 0) {
          draft.StandardSettings = [
            ...draft.StandardSettings,
            {
              id: parseInt(action.payload.standardId),
              component: action.payload.id.toString(),
              isAbsence: 1,
              isGeneralAbsence: 0,
              settings: action.payload.settings,
            },
          ];
        } else {
          key = draft.StandardSettings.findIndex(
            (SS) => SS.component === action.payload.id.toString()
          );
          draft.StandardSettings[key].settings = action.payload.settings;
        }
        break;
      case SET_PERSONAL_DISPLAYSETTINGS:
        key = draft.DisplaySettings.findIndex(
          (DS) => DS.component === action.payload.component
        );
        draft.DisplaySettings[key].settings =
          action.payload.settings;
        break;
      default:
        return draft;
    }
  });

export default settingsReducer;
