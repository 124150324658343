import React from "react";
import MuiAlert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

const SomuraAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert elevation={6} ref={ref} variant="filled" {...props}>
      <AlertTitle>{props.title}</AlertTitle>
      <span>{props.message || "Error!"}</span>
    </MuiAlert>
  );
});

export default SomuraAlert;
