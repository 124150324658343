import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { store } from "redux/store";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import {
  fetchReminderCategories,
  addReminder,
  updateReminder,
  deleteReminder,
  setSelectedReminder,
  fetchYear,
  fetchMonthUser,
} from "redux/index";
import { reminderTimes } from "Constants";
import { minutesFromTime, minutesToTime } from "Components/Utilities";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { Box } from "@mui/material";
import DaySelector from "Components/DaySelector";
import SomuraTextField from "Components/SomuraTextField";
import SomuraTextFieldLargeMUI from "Components/SomuraTextFieldLargeMUI";
import SomuraSwitchField from "Components/SomuraSwitchField";
import SomuraSelectField from "Components/SomuraSelectField";
import SomuraSlider from "Components/SomuraSlider";
import ColorTextItem from "Components/ColorTextItem";
import SomuraSubLine from "Components/SomuraSubLine";
import PreviousButton from "Components/Buttons/PreviousButton";
import NextButton from "Components/Buttons/NextButton";
import CancelButton from "Components/Buttons/CancelButton";
import SaveButton from "Components/Buttons/SaveButton";
import DeleteButton from "Components/Buttons/DeleteButton";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "Calendars/Components/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Draggable from "react-draggable";

function PaperComponent(props) {
  const nodeRef = useRef(null);
  return (
    <Draggable handle={"#draggable-selectionform-title"} nodeRef={nodeRef}>
      <Box ref={nodeRef}>
        <Paper {...props} style={{ maxWidth: 700 }} />
      </Box>
    </Draggable>
  );
}

const mapStateToProps = (state) => {
  return {
    loggedUserId: state.loggeduser.LoggedUser.id,
    reminders: state.reminders.displayReminders,
    reminderCategories: state.remindercategories.ReminderCategories,
    selectedReminder: state.reminders.selectedReminder,
    reminderDate: state.reminders.showReminderDate,
    displayDate: state.selections.DisplayDate,
    selectedLocation: state.selections.selectedLocationId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchReminderCategories: (values) =>
      dispatch(fetchReminderCategories(values)),
    addReminder: (values) => dispatch(addReminder(values)),
    updateReminder: (values) => dispatch(updateReminder(values)),
    deleteReminder: (values) => dispatch(deleteReminder(values)),
    setSelectedReminder: (id) => dispatch(setSelectedReminder(id)),
    fetchYear: (values) => dispatch(fetchYear(values)),
    fetchMonthUser: (values) => dispatch(fetchMonthUser(values)),
  };
};

const RemindersDialog = ({
  loggedUserId,
  reminders,
  reminderCategories,
  selectedReminder,
  reminderDate,
  displayDate,
  selectedLocation,
  fetchReminderCategories,
  addReminder,
  updateReminder,
  deleteReminder,
  setSelectedReminder,
  fetchYear,
  fetchMonthUser,
}) => {
  const { t } = useTranslation();
  const emptyState = {
    id: 0,
    userId: loggedUserId,
    description: "",
    title: "",
    date: reminderDate.date,
    time: DateTime.now().toFormat("HH:mm"),
    durationMinutes: 60,
    durationTime: "01:00",
    durationM: 0,
    durationH: 1,
    enableAlert: false,
    alertTimeId: 4,
    // alertDate: DateTime.now().minus({ minutes: 15 }).toFormat("yyyy-MM-dd"),
    // alertTime: DateTime.now().minus({ minutes: 15 }).toFormat("HH:mm"),
    // alertDateTime: DateTime.now()
    //   .minus({ minutes: 15 })
    //   .toFormat("yyyy-MM-dd HH:mm:ss"),
    isWholeDay: false,
    isAnniversary: false,
    hasDuration: false,
    anniversaryDay: parseInt(DateTime.now().toFormat("dd")),
    anniversaryMonth: parseInt(DateTime.now().toFormat("MM")),
    anniversaryYear: 0,
    categoryId: 0,
  };
  const [state, setState] = useState(emptyState);
  const [selReminder, setSelReminder] = useState(0);

  var reminderTimeOptions = reminderTimes.map((RT) => ({
    label: RT.number + " " + t(RT.text) + " " + t("previously"),
    value: RT.id,
  }));

  reminderTimeOptions.push({
    label: t("Fixed date and time"),
    value: 0,
  });

  const currentYear = parseInt(DateTime.now().toFormat("yyyy"));
  var yearOptions = [{ label: t("Unknown"), value: 0 }];
  for (var i = currentYear; i >= currentYear - 120; i--) {
    yearOptions.push({
      label: i,
      value: i,
    });
  }

  var categoryOptions = reminderCategories.map((RC) => ({
    label: (
      <ColorTextItem backColor={RC.backColor} color={RC.color} text={RC.name} />
    ),
    value: RC.id,
  }));
  categoryOptions.unshift({
    label: <Box sx={{ marginLeft: "6px" }}>{t("No category")}</Box>,
    value: 0,
  });

  const handleClose = () => {
    store.dispatch({ type: "OPEN_REMINDER_DIALOG", payload: null });
    if (reminderDate.calendar === "YearCalendar") {
      fetchYear({
        year: displayDate.substr(0, 4),
        userId: loggedUserId,
        locationId: selectedLocation,
        loggedUserId: loggedUserId,
      });
    }
    if (reminderDate.calendar === "MonthCalendar") {
      fetchMonthUser({
        month: displayDate.substr(5, 2),
        year: displayDate.substr(0, 4),
        companyId: selectedLocation,
        departmentId: -1,
        loggedUserId: loggedUserId,
      });
    }
  };

  const handleSubmit = () => {
    if (state.id === 0) {
      addReminder({
        ...state,
        userId: loggedUserId,
        alertSent: !state.enableAlert,
        alertDateTime: state.enableAlert
          ? DateTime.fromSQL(state.alertDate).isValid
            ? state.alertDate + " " + state.alertTime
            : state.date + " " + state.time
          : state.date + " " + state.time,
      });
    } else {
      updateReminder({
        ...state,
        userId: loggedUserId,
        alertSent: !state.enableAlert,
        alertDateTime: state.enableAlert
          ? DateTime.fromSQL(state.alertDate).isValid
            ? state.alertDate + " " + state.alertTime
            : state.date + " " + state.time
          : state.date + " " + state.time,
      });
    }
    handleClose();
  };

  const handlePrev = () => {
    if (selReminder === 0) {
      setSelReminder(reminders.length - 1);
    } else {
      setSelReminder(selReminder - 1);
    }
  };

  const handleNext = () => {
    if (selReminder === reminders.length - 1) {
      setSelReminder(0);
    } else {
      setSelReminder(selReminder + 1);
    }
  };
  const handleChange = (event) => {
    const name = event.target ? event.target.name : event.name;
    var value = event.target ? event.target.value : event.value;
    setState({ ...state, [name]: value });
  };

  const handleDurationChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    var minutes = 0;
    if (value.length === 5) {
      minutes = minutesFromTime(value);
      setState({
        ...state,
        [name]: value,
        durationMinutes: minutes,
        durationH: Math.floor(minutes / 60),
        durationM: minutes % 60,
      });
    } else {
      setState({
        ...state,
        [name]: value,
      });
    }
  };

  const handleDurationHoursChange = (event, newValue) => {
    setState({
      ...state,
      durationH: newValue,
      durationMinutes: newValue * 60 + state.durationM,
      durationTime: minutesToTime(newValue * 60 + state.durationM),
    });
  };
  const handleDurationMinutesChange = (event, newValue) => {
    setState({
      ...state,
      durationM: newValue,
      durationMinutes: state.durationH * 60 + newValue,
      durationTime: minutesToTime(state.durationH * 60 + newValue),
    });
  };

  const handleAlertChange = (event) => {
    var value = event.target.value;
    if (value > 0) {
      const beforeInterval = reminderTimes.find(
        (RTO) => RTO.id === value
      ).interval;
      setState({
        ...state,
        alertSent: !state.enableAlert,
        alertTimeId: value,
        alertDateTime: DateTime.fromSQL(state.alertDateTime)
          .minus(beforeInterval)
          .toFormat("yyyy-MM-dd HH:mm:ss"),
        alertDate: DateTime.fromSQL(state.date + " " + state.time + ":00")
          .minus(beforeInterval)
          .toFormat("yyyy-MM-dd"),
        alertTime: DateTime.fromSQL(state.date + " " + state.time + ":00")
          .minus(beforeInterval)
          .toFormat("HH:mm"),
      });
    } else {
      setState({ ...state, alertTimeId: value });
    }
  };
  const handleAlertDateChange = (event) => {
    var value = event.target.value;
    setState({
      ...state,
      alertSent: !state.enableAlert,
      alertDate: value,
      alertDateTime: value + " " + state.alertTime,
    });
  };

  const handleAlertTimeChange = (event) => {
    var value = event.target.value;
    setState({
      ...state,
      alertSent: !state.enableAlert,
      alertTime: value,
      alertDateTime: state.alertDate + " " + value + ":00",
    });
  };

  const handleCategoryChange = (event) => {
    var value = event.target.value;
    const category = reminderCategories?.find(
      (RC) => parseInt(RC.id) === parseInt(value)
    );
    setState({
      ...state,
      categoryId: value,
      isAnniversary: category.isAnniversary,
      isWholeDay: category.isWholeDay,
      hasDuration: category.hasDuration,
      alertTimeId: category.defaultTimeId,
    });
  };

  const handleDateChange = (values) => {
    setState({
      ...state,
      anniversaryDay: values.split("-")[0],
      anniversaryMonth: values.split("-")[1],
    });
  };

  const handleYearChange = (event) => {
    const value = event.target.value;
    setState({
      ...state,
      anniversaryYear: value,
    });
  };

  const handleSwitchChange = (event) => {
    const name = event.target.name;
    const value = event.target.checked;
    setState({ ...state, [name]: value });
  };

  const handleDelete = () => {
    deleteReminder({ id: state.id });
    handleClose();
  };

  useEffect(() => {
    if (Object.keys(reminders).length > 0 && reminderDate.mode !== 'N') {
      setState(reminders[selReminder]);
    } else {
      if (reminderDate) {
        setState({
          ...emptyState,
          date: reminderDate.date,
          anniversaryDay: DateTime.fromSQL(reminderDate.date).toFormat("d"),
          anniversaryMonth: DateTime.fromSQL(reminderDate.date).toFormat("M"),
        });
      } else {
        setState(emptyState);
      }
    }
    // eslint-disable-next-line
  }, [selReminder]);

  useEffect(() => {
    fetchReminderCategories({ userId: loggedUserId });
    return () => {
      setSelectedReminder(-1);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Dialog
      sx={{
        minWidth: 1000,
        maxWidth: 1000,
        margin: "auto",
      }}
      open={true}
      onKeyUp={(e) => {
        if (e.key === "Enter" && !e.shiftKey) {
          handleSubmit();
        }
        if (e.key === "Escape") {
          handleClose();
        }
      }}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      PaperComponent={PaperComponent}
      disableEscapeKeyDown={true}
      aria-labelledby="draggable-selectionform-title"
    >
      <DialogTitle
        id="draggable-selectionform-title"
        dialogTitle={
          (reminderDate.mode === "N" && reminderDate.calendar !== "Menu"
            ? t("New reminder")
            : reminders.length === 1
            ? t("Reminder")
            : reminders.length + " " + t("Reminders")) +
          " " +
          t("for") +
          " " +
          DateTime.fromSQL(displayDate).toLocaleString(DateTime.DATE_HUGE)
        }
        closeForm={handleClose}
      />
      <DialogContent
        sx={{
          backgroundColor: "dialog.content",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={7}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <SomuraSubLine raised sx={{ marginTop: "-6px" }}>
                  {reminders.length > 1 &&
                  reminderDate.mode !== "N" &&
                  (reminderDate.calendar || reminderDate.calendar === "Menu")
                    ? t("Target date") +
                      " " +
                      (selReminder + 1) +
                      " " +
                      t("of") +
                      " " +
                      reminders.length
                    : t("Target date")}
                </SomuraSubLine>
              </Grid>
              <Grid item xs={10}>
                <SomuraTextField
                  type="text"
                  name="title"
                  label={t("Title")}
                  value={state.title}
                  maxlength="50"
                  onChange={handleChange}
                />
              </Grid>
              {reminders.length > 1 &&
              (reminderDate.calendar || reminderDate.calendar === "Menu") ? (
                <>
                  <Grid item xs={1}>
                    <PreviousButton
                      sx={{ marginLeft: "-16px" }}
                      onClick={handlePrev}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <NextButton
                      sx={{ marginLeft: "-16px" }}
                      onClick={handleNext}
                    />
                  </Grid>
                </>
              ) : (
                <Grid item xs={2}></Grid>
              )}
              <Grid item xs={4}>
                <SomuraSwitchField
                  name="isWholeDay"
                  label={t("All-day")}
                  checked={state.isWholeDay}
                  onChange={handleSwitchChange}
                  disabled={
                    reminderCategories?.find((RC) => RC.id === state.categoryId)
                      ?.isWholeDay ||
                    reminderCategories?.find((RC) => RC.id === state.categoryId)
                      ?.hasDuration
                  }
                />
              </Grid>
              {state.isAnniversary ? (
                <>
                  <Grid item xs={8}></Grid>
                  <Grid item xs={6}>
                    <DaySelector
                      selectedDay={state.anniversaryDay}
                      selectedMonth={state.anniversaryMonth}
                      onChange={handleDateChange}
                      required={state.hasFixedDate}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <SomuraSelectField
                      name="anniversaryYear"
                      label={t("Year")}
                      options={yearOptions}
                      value={state.anniversaryYear}
                      onChange={handleYearChange}
                    />
                  </Grid>
                  <Grid item xs={1} sx={{ paddingLeft: "2px !important", textAlign: "right" }}>
                    {state.anniversaryYear > 0 ? (
                      <Box
                        sx={{
                          marginTop: "10px",
                        }}
                      >
                        {DateTime.fromSQL(displayDate).year -
                          state.anniversaryYear +
                          "."}
                      </Box>
                    ) : (
                      ""
                    )}
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={8}></Grid>
                  <Grid item xs={5}>
                    <SomuraTextField
                      type="date"
                      name="date"
                      label={"Date"}
                      value={state.date}
                      onChange={handleChange}
                      min={DateTime.now().toSQLDate()}
                    />
                  </Grid>
                  {!state.isWholeDay && (
                    <Grid item xs={4}>
                      <SomuraTextField
                        type="time"
                        name="time"
                        label={"Start"}
                        value={state.time}
                        onChange={handleChange}
                        disabled={state.isWholeDay}
                      />
                    </Grid>
                  )}
                  {state.hasDuration && (
                    <Grid item xs={3}>
                      <SomuraTextField
                        type="text"
                        name="time"
                        label={"End"}
                        value={minutesToTime(
                          minutesFromTime(state.time) + state.durationMinutes
                        )}
                        disabled={true}
                      />
                    </Grid>
                  )}
                </>
              )}
              {state.hasDuration && !state.isWholeDay && (
                <>
                  <Grid item xs={12}>
                    <SomuraSubLine>{t("Duration")}</SomuraSubLine>
                  </Grid>
                  <Grid item xs={12}>
                    <SomuraTextField
                      label={t("Duration")}
                      type="text"
                      name="durationTime"
                      required
                      value={state.durationTime}
                      onChange={handleDurationChange}
                      textAlign="center"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <SomuraSlider
                      label={t("Hours")}
                      value={state.durationH}
                      onChange={handleDurationHoursChange}
                      valueLabelDisplay="auto"
                      valueLabelFormat={(value) => <div>{value}</div>}
                      step={1}
                      min={0}
                      max={23}
                      color={"#0d47a1"}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <SomuraSlider
                      label={t("Minutes")}
                      value={state.durationM}
                      onChange={handleDurationMinutesChange}
                      valueLabelDisplay="auto"
                      valueLabelFormat={(value) => <div>{value}</div>}
                      step={1}
                      min={0}
                      max={59}
                      color={"#0d47a1"}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <SomuraTextFieldLargeMUI
                  type="text"
                  name="description"
                  label={"Description"}
                  rows="4"
                  value={state.description}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={5}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <SomuraSubLine raised sx={{ marginTop: "-6px" }}>
                  {t("Category")}
                </SomuraSubLine>
              </Grid>
              <Grid item xs={12}>
                <SomuraSelectField
                  sx={{
                    marginTop: "6px",
                  }}
                  name="categoryId"
                  maxheight="20rem"
                  options={categoryOptions}
                  value={
                    state.categoryId === undefined ||
                    state.categoryId === null ||
                    categoryOptions.length === 1
                      ? ""
                      : state.categoryId
                  }
                  onChange={handleCategoryChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <SomuraSubLine
                  raised
                  sx={{ marginTop: "12px", marginBottom: "0px" }}
                >
                  {t("Reminder")}
                </SomuraSubLine>
              </Grid>
              <Grid item xs={12}>
                <SomuraSwitchField
                  name="enableAlert"
                  label={t("Remind me")}
                  checked={state.enableAlert}
                  onChange={handleSwitchChange}
                />
              </Grid>
              <Grid item xs={12}>
                <SomuraSelectField
                  name="alertTimeId"
                  options={reminderTimeOptions}
                  style={{ marginTop: "4px" }}
                  disabled={!state.enableAlert}
                  value={state.alertTimeId}
                  onChange={handleAlertChange}
                />
              </Grid>
              {state.enableAlert && state.alertTimeId === 0 ? (
                <>
                  <Grid item xs={6}>
                    <SomuraTextField
                      type="date"
                      name="alertDate"
                      label={"Date"}
                      value={state.alertDate}
                      onChange={handleAlertDateChange}
                      min={DateTime.now().toSQLDate()}
                      disabled={!state.enableAlert}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <SomuraTextField
                      type="time"
                      name="alertTime"
                      label={"Time"}
                      value={state.alertTime}
                      onChange={handleAlertTimeChange}
                      disabled={!state.enableAlert}
                    />
                  </Grid>
                  {/* <Grid item xs={12}>
                    {DateTime.now()
                      .diff(
                        DateTime.fromFormat(
                          state.alertDateTime,
                          "yyyy-MM-dd HH:mm:ss"
                        ),
                        "minutes"
                      )
                      .toObject().minutes > 0 && (
                      <Box component="span" sx={{ color: "#FFF355" }}>
                        {t(
                          "Alert is earlier than the current time and will never start."
                        )}
                      </Box>
                    )}
                  </Grid> */}
                </>
              ) : (
                <Grid item xs={12} sx={{ height: "2.3rem" }}></Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        sx={{
          padding: "4px 10px !important",
          backgroundColor: "dialog.bottom",
        }}
      >
        <CancelButton
          onClick={() => {
            handleClose();
          }}
        />
        <DeleteButton
          disabled={state.id === 0}
          onClick={() => {
            handleDelete();
          }}
        />
        <SaveButton
          disabled={
            state.title === ""
            // state.title === "" ||
            // !DateTime.fromFormat(state.date + state.time, "yyyy-MM-ddHH:mm")
            //   .isValid
          }
          onClick={() => {
            handleSubmit();
          }}
        />
      </DialogActions>
    </Dialog>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(RemindersDialog);
