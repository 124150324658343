import {
  FETCH_COVSTATES_SUCCESS,
  ADD_COVSTATE,
  UPDATE_COVSTATE,
  SET_COVSTATE_ID
} from 'actions/types'

import { covstates, covstateAdd, covstateUpdate } from '../api'
import {
  dataFailure,
  dataSuccess,
} from "./general";

const fetchCovStatesSuccess = (values) => {
  return {
    type: FETCH_COVSTATES_SUCCESS,
    payload: values
  }
}

export const fetchCovStates = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(covstates(values))
      await dispatch(fetchCovStatesSuccess(JSONData))
    } catch(err) {
      await dispatch(dataFailure(err.message))
    }
  }
}

const addCovStateData = (values) => {
  return {
    type: ADD_COVSTATE,
    payload: values
  }
}

export const addCovState = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(covstateAdd(values));
      await dispatch(addCovStateData({ ...values, id: JSONData }));
      await dispatch(dataSuccess())
    } catch(err) {
      await dispatch(dataFailure(err.message))
    }
  }
}

const updateCovStateData = (values) => {
  return {
    type: UPDATE_COVSTATE,
    payload: values
  }
}

export const updateCovState = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(covstateUpdate(values))
      await dispatch(updateCovStateData(values))
      await dispatch(dataSuccess())
    } catch(err) {
      await dispatch(dataFailure(err.message))
    }
  }
}

export const setCovStateId = (values) => {
  return {
    type: SET_COVSTATE_ID,
    payload: values
  }
}